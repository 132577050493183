import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Box from "@material-ui/core/Box";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6b00"
  },
  iconHover: {
    color: "#ff6b00"
  }
})(Rating);

export default function SunRating({rating}) {
  return (
    <div style={{width:"50%", margin:'0 auto'}}>
      <Box component="fieldset" borderColor="transparent">
        <StyledRating
          readOnly
          size='largeW'
          name="customized-color"
          defaultValue={rating}
          getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
          precision={0.5}
          icon={<WbSunnyIcon fontSize="large" />}
        />
      </Box>
    </div>
  );
}

import React from "react";
import serverUrl from "./serverUrl";
import postData from "./postData";

const createProjectEstimationAdvanced = async (values) => {
	const dataObject = await postData(
		serverUrl + "/advanced-size/" + values.selectedCountry.toLowerCase(),
		{
			points: values.points,
			max_rooftop_area: values.max_rooftop_area,
			rooftop_slope: values.rooftop_slope,
			monthly_consumption: parseInt(values.electricity_bill, 10),
			supplier: values.supplier,
		}
	)
		.catch((error) => {
			console.error(error.message);
			return error.message;
		})
		.then((data) => {
			return data;
		});
	return dataObject;
};

export default createProjectEstimationAdvanced;

import { createContext, useContext, useState } from "react";

const LanguagePickerContext = createContext();

export function LanguagePickerProvider({ children }) {
	const [activeLanguage, setActiveLanguage] = useState("sr");

	return (
		<LanguagePickerContext.Provider
			value={{ activeLanguage, setActiveLanguage }}
		>
			{children}
		</LanguagePickerContext.Provider>
	);
}

export function useLanguagePicker() {
	return useContext(LanguagePickerContext);
}

import React, { useEffect, useState } from "react";
import "./ProjectData.css";
import OutputData from "../OutputData/OutputData";
import BarChart from "../Charts/BarChart";
import ControlledExpansionPanels from "../ControlledExpansionPanels/ControlledExpansionPanels";
import { useTranslation } from "react-i18next";

function ProjectData({
	projectData,
	limited = false,
	type = "regular",
	designerLimitation = false,
}) {
	const { t } = useTranslation();

	return (
		<div
			className={
				type == "homepage"
					? "project-data-container"
					: "project-data-container project-data-margin-top"
			}
		>
			<div className={type == "homepage" ? "project-data-grid" : ""}>
				<div className="basic-project-data white-section">
					<div>
						<h3 className="project-section-header">
							{t("project_data_basic_information")}
						</h3>
						{limited || designerLimitation ? (
							<>
								<OutputData
									value={projectData.max_specified_investment}
									unit={projectData.currency}
									label={t("project_data_maximum_investment")}
								></OutputData>
								<OutputData
									value={projectData.annual_consumption}
									unit="kWh"
									label={t("annual_consumtion_estimation")}
								></OutputData>
								<OutputData
									value={projectData.max_rooftop_area}
									unit="m"
									label={t("project_data_max_rooftop_area")}
									type="m"
								></OutputData>
								<OutputData
									value={projectData.rooftop_slope}
									unit="  °"
									label={t("project_data_rooftop_slope")}
								></OutputData>
								<OutputData
									value={projectData.rooftop_azimuth}
									unit="  °"
									label={t("project_data_rooftop_azimuth")}
								></OutputData>
							</>
						) : (
							<>
								<OutputData
									value={projectData.installation_size}
									unit="kW"
									label={t("project_data_installation_size")}
								></OutputData>
								<OutputData
									value={projectData.rooftop_area_needed}
									unit="m"
									label={t("project_data_rooftop_area")}
									type="m"
								></OutputData>
								<OutputData
									value={projectData.estimated_profit}
									unit={projectData.currency}
									label={t("project_data_estimated_profit")}
								></OutputData>
								<OutputData
									value={projectData.investment_needed}
									unit={projectData.currency}
									label={t("project_data_investment_needed")}
								></OutputData>
								<OutputData
									value={projectData.repayment_period}
									unit={t("project_data_yrs")}
									label={t("project_data_repeyment_period")}
								></OutputData>
								<OutputData
									value={projectData.life_span}
									unit={t("project_data_yrs")}
									label={t("project_data_roof_life_span")}
								></OutputData>
								<ControlledExpansionPanels
									rooftop_slope={projectData.rooftop_slope}
									rooftop_azimuth={projectData.rooftop_azimuth}
									module_generation={projectData.module_generation}
									number_of_modules={projectData.number_of_modules}
									system_generation={projectData.system_generation}
									annual_consumption={projectData.annual_consumption}
								></ControlledExpansionPanels>
							</>
						)}
					</div>
				</div>
				{limited ? (
					""
				) : (
					<>
						{designerLimitation ? (
							""
						) : (
							<div
								className="cashflow-project-data white-section"
								style={{ marginLeft: 0 }}
							>
								<h3 className="project-section-header">
									{t("cash_flow_diagram")}
								</h3>
								<BarChart
									valute={projectData.currency}
									cashFlow={projectData.cash_flow}
								></BarChart>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default ProjectData;

import React from "react";

import { useTranslation } from "react-i18next";

import Image from "../../../../Assets/Images/articleImages/Article 11-1.png";
import Image2 from "../../../../Assets/Images/articleImages/Article 11-2.jpg";
import Image3 from "../../../../Assets/Images/articleImages/Article 11-3.jpg";
import Image4 from "../../../../Assets/Images/articleImages/Article 11-4.jpg";
import Image5 from "../../../../Assets/Images/articleImages/Article 11-5.png";
import Image5ENG from "../../../../Assets/Images/articleImages/Article 11-5ENG.png";
import { Helmet } from "react-helmet";

import ArticleImage from "../ArticleImage";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-11-h")}</h1>
			<p>{t("knowledge-base-11-p1")}</p>
			<h3>{t("knowledge-base-11-h1")}</h3>
			<p>
				{t("knowledge-base-11-p2")}
				<br></br>
				<br></br>
				{t("knowledge-base-11-p3")}
				<ArticleImage src={Image} heading={t("image11-1")}></ArticleImage>
				{t("knowledge-base-11-p4")}
				<br></br>
				<br></br>
				{t("knowledge-base-11-p5")}
			</p>
			<h3>{t("knowledge-base-11-h2")}</h3>
			<p>{t("knowledge-base-11-p6")}</p>
			<ArticleImage src={Image2} heading={t("image11-2")}></ArticleImage>
			<h3>{t("knowledge-base-11-h3")}</h3>
			<p>{t("knowledge-base-11-p7")}</p>
			<ArticleImage src={Image3} heading={t("image11-3")}></ArticleImage>

			<h3>{t("knowledge-base-11-h4")}</h3>
			<p>
				{t("knowledge-base-11-p8")}
				<ArticleImage src={Image4} heading={t("image11-4")}></ArticleImage>

				{t("knowledge-base-11-p9")}
			</p>
			<ArticleImage
				src={Image5}
				srcENG={Image5ENG}
				margin="narrow"
			></ArticleImage>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-11-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-11-p1")} />
				<title>{t("knowledge-base-11-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-11-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article2;

import React from "react";
import { storage } from "../../Services/firebase/firebase";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

async function deleteFile(fileUrl, t) {
	const fileRef = storage.refFromURL(fileUrl);

	await toast.promise(
		fileRef
			.delete()
			.then(() => {})
			.catch((error) => {
				return error;
			}),
		{
			loading: t("loading"),
			success: t("successfully_deleted_file"),
			error: (err) => `This just happened: ${err.toString()}`,
		}
	);
}

export default deleteFile;

import React from "react";
import useForm from "./useForm";
import { Button } from "../Main/Buttons/Button";
import "./Form.css";
import "./MakeAnOfferForm.css";
import { useTranslation } from "react-i18next";
import { useValidateInvestorData } from "../../Services/FormValidation/validateInvestorData";

function AskForQuoteDataForm({ closeModal, offerRequestSubmit }) {
	const submitForm = async (values) => {
		const data = await offerRequestSubmit(values);

		closeModal();
	};
	const validateInvestorData = useValidateInvestorData();

	const {
		handleChange: handleChange,
		values: values,
		handleSubmit: handleSubmit,
		errors: errors,
		handleFileUrl: handleFileUrl,
	} = useForm(submitForm, validateInvestorData);

	const { t } = useTranslation();

	return (
		<div className="form-container form-container-make-an-offer-form">
			<div className="form-content-right">
				<form className="form" onSubmit={handleSubmit}>
					<i
						class="flaticon-close close-button"
						type="button"
						style={{ borderWidth: 0 }}
						onClick={closeModal}
					></i>
					<h2 style={{ margin: "20px 10px 20px 10px" }}>
						{t("contractor_form_title")}
					</h2>
					<div className="form-inputs">
						<label htmlFor="firstname" className="form-label">
							{t("investor_form_first_name")}{" "}
						</label>
						<input
							id="firstname"
							type="text"
							name="firstname"
							placeholder={t("investor_form_first_name_placeholder")}
							className="form-input"
							value={values.firstname}
							onChange={handleChange}
						/>
						{errors.firstname && <p>{errors.firstname}</p>}
					</div>

					<div className="form-inputs">
						<label htmlFor="lastname" className="form-label">
							{t("investor_form_last_name")}{" "}
						</label>
						<input
							id="lastname"
							type="text"
							name="lastname"
							placeholder={t("investor_form_last_name_placeholder")}
							className="form-input"
							value={values.lastname}
							onChange={handleChange}
						/>
						{errors.lastname && <p>{errors.lastname}</p>}
					</div>

					<div className="form-inputs">
						<label htmlFor="email" className="form-label">
							{t("contractor_form_email")}{" "}
						</label>
						<input
							id="email"
							type="email"
							name="email"
							placeholder={t("contractor_form_email_placeholder")}
							className="form-input"
							value={values.email}
							onChange={handleChange}
						/>
						{errors.email && <p>{errors.email}</p>}
					</div>

					<div className="form-inputs last-form-input">
						<label htmlFor="number" className="form-label">
							{t("contractor_form_phone_number")}{" "}
						</label>
						<input
							id="number"
							type="tel"
							name="number"
							placeholder={t("contractor_form_phone_number_placeholder")}
							className="form-input"
							value={values.number}
							onChange={handleChange}
						/>
						{errors.number && <p>{errors.number}</p>}
					</div>

					<Button
						buttonStyle="btn--primary"
						buttonSize="btn--large"
						type="submit"
					>
						{t("confirm")}
					</Button>
				</form>
			</div>
		</div>
	);
}

export default AskForQuoteDataForm;

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LanguageRedirect = () => {
	const history = useHistory();
	const location = useLocation();

	const { i18n } = useTranslation();

	useEffect(() => {
		const newUrl = `/${i18n.language}${location.pathname}${location.search}`;

		history.push(newUrl);
	}, [history, location]);

	return null;
};

export default LanguageRedirect;

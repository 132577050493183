import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

export default function ThirdTileIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24" height="24" viewBox="0 0 24 24" version="1.1" id="svg6">
				<path
					d="m 8.4230769,15.086538 -1.9038461,1.86577 v -5.356154 h 1.9038461 m 3.1730771,2.322692 -0.996323,-0.850384 -0.9075233,0.837692 V 9.0576923 h 1.9038463 m 3.173077,3.8076927 -1.903846,1.903846 V 6.5192308 h 1.903846 m 1.783246,6.2256002 -1.148631,-1.148677 h 3.173077 v 3.173077 L 17.440938,13.633292 12.865385,18.170769 10.663246,16.254231 8.2644,18.576923 H 6.5192308 l 4.1059842,-4.023461 2.24017,1.891153"
					id="path4"
					strokeWidth="0.461538"
				/>
			</svg>
		</SvgIcon>
	);
}

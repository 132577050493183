import React from "react";
import { useTranslation } from "react-i18next";
import Image from "../../../../Assets/Images/articleImages/Article 9-1.png";
import ImageENG from "../../../../Assets/Images/articleImages/Article 9-1ENG.png";
import Image2 from "../../../../Assets/Images/articleImages/Article 9-2.png";
import Image2ENG from "../../../../Assets/Images/articleImages/Article 9-2ENG.png";
import { Helmet } from "react-helmet";

import ArticleImage from "../ArticleImage";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-9-h")}</h1>
			<p>
				{t("knowledge-base-9-p1")}
				<br></br>
				<br></br>
				{t("knowledge-base-9-p2")}
				<br></br>
				<br></br>
				{t("knowledge-base-9-p3")}
				<ArticleImage
					src={Image}
					srcENG={ImageENG}
					margin="narrow"
				></ArticleImage>
				{t("knowledge-base-9-p4")}
				<ArticleImage
					src={Image2}
					srcENG={Image2ENG}
					margin="narrow"
				></ArticleImage>
				{t("knowledge-base-9-p5")}
			</p>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-9-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-9-p1")} />
				<title>{t("knowledge-base-9-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-9-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article2;

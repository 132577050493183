import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes, FaRocket } from "react-icons/fa";
import { Button } from "../../Components/Main/Buttons/Button";
import "./Navbar.css";
import { IconContext } from "react-icons/lib";
import { useAuth } from "../Contexts/auth-context";
import Avatar from "@material-ui/core/Avatar";
import LanguagePicker from "../Main/LanguagePicker/LanguagePicker";
import { useTranslation } from "react-i18next";
import FloatingMenu from "./FloatingMenu";

function Navbar({ backgroundStyle, showNavbar = true }) {
	const { i18n } = useTranslation();

	const [click, setClick] = useState(false);
	const [button, setButton] = useState(true);
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);
	const { currentUser, signOut, userData } = useAuth();

	const getAvatarLink = () => {
		if (currentUser == "contractor") {
			return "/" + i18n.language + "/contractor";
		} else if (currentUser == "investor") {
			return "/" + i18n.language + "/profile";
		} else {
			return "/";
		}
	};

	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(false);
		} else {
			setButton(true);
		}
	};
	useEffect(() => {
		showButton();
	}, []);

	const logOut = () => {
		closeMobileMenu();
		signOut();
	};

	window.addEventListener("resize", showButton);
	const { t } = useTranslation();

	return (
		<>
			<div
				className="navbar-absolute"
				style={showNavbar ? {} : { display: "none" }}
			>
				<IconContext.Provider value={{ color: "#fff" }}>
					<div className="navbar" style={{ background: backgroundStyle }}>
						<div className="navbar-container container">
							<Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
								<div className="navbar-logo-div"></div>
							</Link>
							<FloatingMenu button={button}></FloatingMenu>

							<div className="menu-icon" onClick={handleClick}>
								{click ? <FaTimes /> : <FaBars />}
							</div>
							<ul className={click ? "nav-menu active" : "nav-menu"}>
								<FloatingMenu
									button={!button}
									handleClick={handleClick}
								></FloatingMenu>
								{/* <li className="nav-btn">
									{button ? (
										currentUser == "loged-out" || currentUser == null ? (
											<Link
												to={{
													pathname: "/" + i18n.language + "/log-in",
													state: { redirect: "normal" },
												}}
												className="btn-link"
											>
												<Button buttonStyle="btn--outline">
													{t("login_form_log_in")}
												</Button>
											</Link>
										) : (
											<Link to="/" className="btn-link">
												<Button buttonStyle="btn--outline" onClick={signOut}>
													{t("log_out")}
												</Button>
											</Link>
										)
									) : currentUser == "loged-out" || currentUser == null ? (
										<Link
											to={{
												pathname: "/" + i18n.language + "/log-in",
												state: { redirect: "normal" },
											}}
											className="btn-link"
										>
											<Button
												buttonStyle="btn--outline"
												buttonSize="btn--mobile"
												onClick={closeMobileMenu}
											>
												{t("login_form_log_in")}
											</Button>
										</Link>
									) : (
										<Link to="/" className="btn-link">
											<Button
												buttonStyle="btn--outline"
												buttonSize="btn--mobile"
												onClick={logOut}
											>
												{t("log_out")}
											</Button>
										</Link>
									)}
								</li> */}
								{/* <li className="nav-btn">
									{button ? (
										currentUser == "loged-out" || currentUser == null ? (
											<Link
												to={"/" + i18n.language + "/register"}
												className="btn-link"
											>
												<Button buttonStyle="btn--primary">
													{t("register_form_register")}
												</Button>
											</Link>
										) : (
											<Link to={getAvatarLink()} className="btn-link">
												<div className="nav-avatar-container">
													<Avatar
														alt={`${userData.name} ${userData.surname}`}
														src={userData.photo}
														sx={{ width: "30px", height: "30px" }}
													/>
												</div>
											</Link>
										)
									) : currentUser == "loged-out" || currentUser == null ? (
										<Link
											to={"/" + i18n.language + "/register"}
											className="btn-link"
											onClick={closeMobileMenu}
										>
											<Button
												buttonStyle="btn--primary btn"
												buttonSize="btn--mobile"
											>
												{t("register_form_register")}
											</Button>
										</Link>
									) : (
										<Link to={getAvatarLink()} className="btn-link">
											<div
												className="nav-avatar-container mobile-avatar-container"
												onClick={closeMobileMenu}
											>
												<Avatar
													alt={`${userData.name} ${userData.surname}`}
													src={userData.photo}
													sx={{ width: "30px", height: "30px" }}
												/>
												<h3>
													{currentUser == "investor"
														? `${userData.name} ${userData.surname}`
														: `${userData.company_name}`}
												</h3>
											</div>
										</Link>
									)}
								</li> */}
								<li className="nav-btn">
									<LanguagePicker />
								</li>
							</ul>
						</div>
					</div>
				</IconContext.Provider>
			</div>
		</>
	);
}

export default Navbar;

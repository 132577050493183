import React from "react";
import { useTranslation } from "react-i18next";
import Image from "../../../../Assets/Images/articleImages/Article 19-1.png";
import { Helmet } from "react-helmet";

import ArticleImage from "../ArticleImage";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-19-h")}</h1>
			<p>{t("knowledge-base-19-p1")}</p>
			<ArticleImage src={Image} heading={t("image19-1")}></ArticleImage>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-19-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-19-p1")} />
				<title>{t("knowledge-base-19-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-19-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article2;

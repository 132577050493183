import React from "react";
import "./Footer.css";
import { Button } from "../Main/Buttons/Button";
import { Link } from "react-router-dom";
import {
	FaFacebook,
	FaInstagram,
	FaYoutube,
	FaTwitter,
	FaLinkedin,
} from "react-icons/fa";
import { FaRocket } from "react-icons/fa";

function Footer() {
	return (
		<div className="footer-container">
			{/* <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Join our exclusive membership to receive the latest news and trips
        </p>
        <p className="footer-subscription-text">
          You can unsubscribe at any time.
        </p>
        <div className="input-areas">
          <form>
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Your Email"
            />
            <Button buttonStyle="btn--outline">Subscribe</Button>
          </form>
        </div>
      </section> */}
			<div className="footer-links">
				<div className="footer-link-wrapper">
					<div className="footer-link-items">
						<h2>About Roofko.rs</h2>
						<Link to="/aboutUs">About Us</Link>
						<Link to="/">Contractor Relations</Link>
						<Link to="/">Careers</Link>
					</div>
					<div className="footer-link-items">
						<h2>Explore</h2>
						<Link to="/">Write a review</Link>
						<Link to="/">Add a Place</Link>
						<Link to="/">Help Center</Link>
					</div>
				</div>
				<div className="footer-link-wrapper">
					<div className="footer-link-items">
						<h2>Do Business </h2>
						<Link to="/">Owners</Link>
						<Link to="/">Advertise with Us</Link>
						<Link to="/">Become a Partner</Link>
					</div>
					<div className="footer-link-items">
						<h2>Support</h2>
						<Link to="/">Our COVID-19 Response</Link>
						<Link to="/">Resign project options</Link>
						<Link to="/">Trust and Safety</Link>
					</div>
				</div>
			</div>
			<section className="social-media">
				<div className="social-media-wrap">
					<div className="footer-logo">
						<small className="website-rights">
							<a href="mailto:info@roofko.me">
								<span>info@roofko.me</span>
							</a>
						</small>
					</div>
					<small className="website-rights">
						<span>RENERGY</span>
						<span style={{ color: "#ff6b00", fontSize: "130%" }}>ME</span>© 2024
					</small>
					<div className="social-icons">
						<Link
							className="social-icon-link"
							to={{
								pathname: "https://www.facebook.com/roofko.mne",
							}}
							target="_blank"
							aria-label="Facebook"
						>
							<FaFacebook />
						</Link>
						<Link
							className="social-icon-link"
							to={{
								pathname: "https://www.instagram.com/roofko.me/",
							}}
							target="_blank"
							aria-label="Instagram"
						>
							<FaInstagram />
						</Link>
						<Link
							className="social-icon-link"
							to={{
								pathname: "https://twitter.com/roofko_me",
							}}
							target="_blank"
							aria-label="Twitter"
						>
							<FaTwitter />
						</Link>
						<Link
							className="social-icon-link"
							to={{
								pathname: "https://www.linkedin.com/company/roofko-me/",
							}}
							target="_blank"
							aria-label="LinkedIn"
						>
							<FaLinkedin />
						</Link>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Footer;

import React from "react";
import { useTranslation } from "react-i18next";
import ArticleImage from "../ArticleImage";
import Image1 from "../../../../Assets/Images/articleImages/Article 2-1.png";
import Image1ENG from "../../../../Assets/Images/articleImages/Article 2-1ENG.png";
import { Helmet } from "react-helmet";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-2-h")}</h1>
			<p>
				{t("knowledge-base-2-p1")}
				<ArticleImage
					src={Image1}
					srcENG={Image1ENG}
					heading={t("image2-1")}
				></ArticleImage>

				{t("knowledge-base-2-p2")}
				<br></br>
				<br></br>
				{t("knowledge-base-2-p3")}
				<br></br>
				<br></br>
				{t("knowledge-base-2-p4")}
			</p>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-2-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-2-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
				<title>{t("knowledge-base-2-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-2-p1")} />
			</Helmet>
		</>
	);
}

export default Article2;

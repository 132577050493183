import React from "react";
import Modal from "react-modal";
import OfferDetailsModalData from "./OfferDetailsModalData";

const customStyles = {
	content: {
		height: "90vh",
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0",
		borderRadius: "20px",
	},
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function OfferDetailsModal({
	modalIsOpen,
	closeModal,
	offerData,
	acceptOfferSubmit,
	valute,
}) {
	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				// onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Example Modal"
			>
				<OfferDetailsModalData
					closeModal={closeModal}
					offerData={offerData}
					acceptOfferSubmit={acceptOfferSubmit}
					valute={valute}
				></OfferDetailsModalData>
			</Modal>
		</div>
	);
}

export default OfferDetailsModal;

import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Article0() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-5-h")}</h1>
			<p>{t("knowledge-base-5-p1")}</p>
			<br></br>
			<p className="indend">
				{t("knowledge-base-5-p2")}
				<br></br>
				{t("knowledge-base-5-p3")}
				<br></br>
				{t("knowledge-base-5-p4")}
			</p>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-5-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-5-p1")} />
				<title>{t("knowledge-base-5-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-5-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article0;

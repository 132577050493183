import { storage } from "../../Services/firebase/firebase";
import toast from "react-hot-toast";

const fileUpload = async (file, name, folder, t) => {
	try {
		let self = this;

		const fileRef = storage.ref().child(`${folder}/` + `${name}`);

		const uploadTaskSnapshot = await toast.promise(fileRef.put(file), {
			loading: t("loading"),
			success: t("file_successfully_uploaded"),
			error: `There is error file upload`,
		});

		const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

		self = downloadURL;

		return downloadURL;
	} catch (error) {
		alert("Image uploading failed!");
	}
};

export default fileUpload;

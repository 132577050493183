import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LoadingPage from "../Components/LoadingPage/LoadingPage";
import PageHero from "../Components/Main/PageHero/PageHero";
import KnowledgeMenu from "../Components/Main/KnowledgeMenu/KnowledgeMenu";
import { useTranslation } from "react-i18next";
import "./PageOfKnowledge.css";
import Typography from "@material-ui/core/Typography";
import Article1 from "../Components/Main/PageOfKnowledge/Articles/Article1";
import Article2 from "../Components/Main/PageOfKnowledge/Articles/Article2";
import Article3 from "../Components/Main/PageOfKnowledge/Articles/Article3";
import Article4 from "../Components/Main/PageOfKnowledge/Articles/Article4";
import Article0 from "../Components/Main/PageOfKnowledge/Articles/Article0";
import Article5 from "../Components/Main/PageOfKnowledge/Articles/Article5";
import Article6 from "../Components/Main/PageOfKnowledge/Articles/Article6";
import Article7 from "../Components/Main/PageOfKnowledge/Articles/Article7";
import Article8 from "../Components/Main/PageOfKnowledge/Articles/Article8";
import Article9 from "../Components/Main/PageOfKnowledge/Articles/Article9";
import Article10 from "../Components/Main/PageOfKnowledge/Articles/Article10";
import Article11 from "../Components/Main/PageOfKnowledge/Articles/Article11";
import Article12 from "../Components/Main/PageOfKnowledge/Articles/Article12";
import Article13 from "../Components/Main/PageOfKnowledge/Articles/Article13";
import Article14 from "../Components/Main/PageOfKnowledge/Articles/Article14";
import Article15 from "../Components/Main/PageOfKnowledge/Articles/Article15";
import Article16 from "../Components/Main/PageOfKnowledge/Articles/Article16";
import Article17 from "../Components/Main/PageOfKnowledge/Articles/Article17";
import Article18 from "../Components/Main/PageOfKnowledge/Articles/Article18";
import Article19 from "../Components/Main/PageOfKnowledge/Articles/Article19";
import Article20 from "../Components/Main/PageOfKnowledge/Articles/Article20";

import {
	createTheme,
	responsiveFontSizes,
	ThemeProvider,
} from "@material-ui/core/styles";

function PageOfKnowledge() {
	const articles = [
		{ title: "Article 0", content: <Article0 />, id: 0 },
		{ title: "Article 1", content: <Article1 />, id: 1 },
		{ title: "Article 2", content: <Article2 />, id: 2 },
		{ title: "Article 3", content: <Article3 />, id: 3 },
		{ title: "Article 4", content: <Article4 />, id: 4 },
		{ title: "Article 5", content: <Article5 />, id: 5 },
		{ title: "Article 6", content: <Article6 />, id: 6 },
		{ title: "Article 7", content: <Article7 />, id: 7 },
		{ title: "Article 8", content: <Article8 />, id: 8 },
		{ title: "Article 9", content: <Article9 />, id: 9 },
		{ title: "Article 10", content: <Article10 />, id: 10 },
		{ title: "Article 11", content: <Article11 />, id: 11 },
		{ title: "Article 12", content: <Article12 />, id: 12 },
		{ title: "Article 13", content: <Article13 />, id: 13 },
		{ title: "Article 14", content: <Article14 />, id: 14 },
		{ title: "Article 15", content: <Article15 />, id: 15 },
		{ title: "Article 16", content: <Article16 />, id: 16 },
		{ title: "Article 17", content: <Article17 />, id: 17 },
		{ title: "Article 18", content: <Article18 />, id: 18 },
		{ title: "Article 19", content: <Article19 />, id: 19 },
		{ title: "Article 20", content: <Article20 />, id: 20 },
	];

	const articleUrl = [
		{ id: 0, urlPath: "types-of-solar-systems" },
		{ id: 1, urlPath: "grid-tied-solar-systems" },
		{ id: 2, urlPath: "hybrid-solar-systems" },
		{ id: 3, urlPath: "off-grid-solar-systems" },
		{ id: 4, urlPath: "choosing-a-solar-system" },
		{ id: 5, urlPath: "introduction-to-solar-energy" },
		{ id: 6, urlPath: "what-is-solar-energy" },
		{ id: 7, urlPath: "history-of-solar-energy" },
		{ id: 8, urlPath: "advantages-and-disadvantages-of-solar-energy" },
		{ id: 9, urlPath: "solar-panels" },
		{ id: 10, urlPath: "inverter" },
		{ id: 11, urlPath: "mounting-structures" },
		{ id: 12, urlPath: "batteries" },
		{ id: 13, urlPath: "equipment-for-solar-power-plants" },
		{ id: 14, urlPath: "installation-requirments" },
		{ id: 15, urlPath: "roof-orientation" },
		{ id: 16, urlPath: "roof-tilt" },
		{ id: 17, urlPath: "roof-covering-type" },
		{ id: 18, urlPath: "roof-free-area" },
		{ id: 19, urlPath: "type-of-grid-connection" },
		{ id: 20, urlPath: "is-solar-energy-right-for-you" },
	];

	const { i18n } = useTranslation();
	var loading = false;
	const history = useHistory();
	let theme = createTheme();
	theme = responsiveFontSizes(theme);
	const [selectedId, setSelectedId] = useState(5);

	const [isInitializingFromURL, setIsInitializingFromURL] = useState(false);

	useEffect(() => {
		// Update the state based on the ID parameter
		setIsInitializingFromURL(false);
		const currentURL = window.location.href;
		const parts = currentURL.split("/");
		const lastPart = parts.pop();
		const cleanUrl = lastPart.split("?")[0];

		if (selectedId !== articleUrl.find((x) => x.urlPath === cleanUrl).id) {
			if (selectedId !== articleUrl.find((x) => x.urlPath === cleanUrl).id) {
				setSelectedId(articleUrl.find((x) => x.urlPath === cleanUrl).id);
			}
		}
		setIsInitializingFromURL(true);
	}, []);

	useEffect(() => {
		// Update the URL based on the state change
		if (!isInitializingFromURL) {
			const currentURL = window.location.pathname;
			const parts = currentURL.split("/");
			var newLastPart = articleUrl.find((x) => x.id === selectedId).urlPath;
			parts[parts.length - 1] = newLastPart;
			const newURL = parts.join("/");
			history.push(newURL);
		}
		setIsInitializingFromURL(false);
	}, [selectedId]);
	return (
		<div className="profile-wrapper knowledgemenu">
			{loading ? (
				<LoadingPage></LoadingPage>
			) : (
				<>
					<PageHero></PageHero>

					<ThemeProvider theme={theme}>
						<div className="profile-container">
							<KnowledgeMenu
								setSelectedId={setSelectedId}
								selectedId={selectedId}
							></KnowledgeMenu>
							{articles.map((article) => (
								<>
									{selectedId === article.id ? (
										<div className="profile-down">
											<div className="white-section project-data-margin-top">
												{article.content}
											</div>
										</div>
									) : (
										""
									)}
								</>
							))}
						</div>
					</ThemeProvider>
				</>
			)}
		</div>
	);
}

export default PageOfKnowledge;

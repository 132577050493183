import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";

function createData(name, calories) {
	return { name, calories };
}

export default function BasicTable() {
	const { t } = useTranslation();
	const rows = [
		createData(t("table2-21"), t("table2-22")),
		createData(t("table2-31"), t("table2-32")),
		createData(t("table2-41"), t("table2-42")),
		createData(t("table2-51"), t("table2-52")),
		createData(t("table2-61"), t("table2-62")),
	];
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 200 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell align="center" style={{ width: "50%" }}>
							{t("table2-11")}
						</TableCell>
						<TableCell align="center" style={{ width: "50%" }}>
							{t("table2-12")}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow
							key={row.name}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TableCell component="th" scope="row" align="center">
								{row.name}
							</TableCell>
							<TableCell component="th" scope="row" align="center">
								{row.calories}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

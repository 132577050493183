import { useState, useEffect } from "react";
import { useAuth } from "../../Components/Contexts/auth-context";
import fileUpload from "../../Services/firebase/fileUpload";
import { v4 as uuidv4 } from "uuid";
import deleteFile from "../../Services/firebase/deleteFile";
import { useTranslation } from "react-i18next";

const useForm = (callback, validate, profileData, image, folder, type) => {
	const { currentUser } = useAuth();

	const { t } = useTranslation();

	const [values, setValues] = useState({
		email: "",
		password: "",
		password2: "",
		phoneNumber: "",
		firstname: "",
		lastname: "",
		contractorName: "",
		username: "",
		fileUrl: "",
		photo: "",
		number: "",
		address: "",
	});

	const changeUrlValue = async (imageProp) => {
		var fileUrl = await fileUpload(imageProp, `Image-${uuidv4()}`, folder, t);

		if (!imageProp) {
			fileUrl = profileData.photo;
		} else {
			deleteFile(profileData.photo, t);
		}
		return fileUrl;
	};

	useEffect(() => {
		if (currentUser == "investor") {
			setValues({
				...values,
				number: profileData.phone_number,
				firstname: profileData.name,
				lastname: profileData.surname,
				address: profileData.address,
				email: profileData.email,
			});
		} else if (currentUser == "contractor") {
			setValues({
				...values,
				number: profileData.phone_number,
				address: profileData.address,
				email: profileData.email,
				contractorName: profileData.company_name,
			});
		}
	}, []);

	const [errors, setErrors] = useState({});
	// Change to false when done testing
	const [isSubmitting, setisSubmitting] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setErrors(validate(values));
		setisSubmitting(true);
	};

	useEffect(() => {
		(async function () {
			if (isSubmitting && errors.errorsTag == false) {
				if (type == "edit") {
					var fileUrl = await changeUrlValue(image);
				}

				callback(values, fileUrl);
			} else {
			}
		})();
	}, [errors]);

	return { handleChange, values, handleSubmit, errors };
};
export default useForm;

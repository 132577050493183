import React from "react";
import { useTranslation } from "react-i18next";
import Table1 from "../Tables/Table1";
import { Helmet } from "react-helmet";

function Article21() {
	const { t } = useTranslation();

	return (
		<>
			<p>{t("knowledge-base-21-p1")}</p>
			<br></br>
			<br></br>
			<p className="indend">
				{t("knowledge-base-21-p2")}
				<br></br>
				{t("knowledge-base-21-p3")}
				<br></br>
				{t("knowledge-base-21-p4")}
				<br></br>
				{t("knowledge-base-21-p5")}
				<br></br>
				{t("knowledge-base-21-p6")}
				<br></br>
				{t("knowledge-base-21-p7")}
			</p>
			<br></br>
			<br></br>
			<p>{t("knowledge-base-21-p8")}</p>

			<br></br>
			<div>
				<ul className="city-grid">
					<li>Zrenjanin</li>
					<li>Novi Bečej</li>
					<li>Nova Crnja</li>
					<li>Žitište</li>
					<li>Sečanj</li>
					<li>Kikinda</li>
					<li>Senta</li>
					<li>Novi Kneževac</li>
					<li>Kanjiža</li>
					<li>Odžaci</li>
					<li>Kula</li>
					<li>Sombor</li>
					<li>Apatin</li>
					<li>Stara Pazova</li>
					<li>Pećinci</li>
					<li>Irig</li>
					<li>Inđija</li>
					<li>Šid</li>
					<li>Sremska Mitrovica</li>
					<li>Valjevo</li>
					<li>Gornji Milanovac</li>
					<li>Ivanjica</li>
					<li>Čačak</li>
					<li>Lučani</li>
					<li>Subotica</li>
					<li>Mali Iđoš</li>
					<li>Bačka Topola</li>
					<li>Pančevo</li>
					<li>Vršac</li>
					<li>Plandište</li>
					<li>Kovin</li>
					<li>Kovačica</li>
					<li>Bela Crkva</li>
					<li>Alibunar</li>
					<li>Opovo</li>
					<li>Bač</li>
					<li>Bački Petrovac</li>
					<li>Vrbas</li>
					<li>Beočin</li>
					<li>Temerin</li>
					<li>Srbobran</li>
					<li>Novi Sad</li>
					<li>Žabalj</li>
					<li>Sremski Karlovci</li>
					<li>Titel</li>
					<li>Bečej</li>
					<li>Smederevska Palanka</li>
					<li>Smederevo</li>
					<li>Pirot</li>
					<li>Bela Palanka</li>
					<li>Babušnica</li>
					<li>Dimitrovgrad</li>
					<li>Leskovac</li>
					<li>Lebane</li>
					<li>Bojnik</li>
					<li>Vlasotince</li>
					<li>Majdanpek</li>
					<li>Bor</li>
					<li>Negotin</li>
					<li>Kruševac</li>
					<li>Trstenik</li>
					<li>Aleksandrovac</li>
					<li>Brus</li>
					<li>Varvarin</li>
					<li>Svilajnac</li>
					<li>Paraćin</li>
					<li>Despotovac</li>
					<li>Ćuprija</li>
					<li>Rača</li>
					<li>Kragujevac</li>
					<li>Batočine</li>
					<li>Knić</li>
					<li>Lapovo</li>
					<li>Topola</li>
					<li>Aranđelovac</li>
					<li>Knjaževac</li>
					<li>Boljevac</li>
					<li>Zaječar</li>
					<li>Sokobanja</li>
					<li>Vranje</li>
					<li>Bujanovac</li>
					<li>Trgovište</li>
					<li>Bosilegrad</li>
					<li>Vladičin Han</li>
					<li>Preševo</li>
					<li>Žagubica</li>
					<li>Žabari</li>
					<li>Malo Crniće</li>
					<li>Golubac</li>
					<li>Veliko Gradište</li>
					<li>Kučevo</li>
					<li>Požarevac</li>
					<li>Petrovac na Mlavi</li>
					<li>Kraljevo</li>
					<li>Vrnjačka Banja</li>
					<li>Raška</li>
					<li>Tutin</li>
					<li>Novi Pazar</li>
					<li>Šabac</li>
					<li>Loznica</li>
					<li>Mali Zvornik</li>
					<li>Ljubovija</li>
					<li>Vladimirci</li>
					<li>Koceljeva</li>
					<li>Bogatić</li>
					<li>Nova Varoš</li>
					<li>Užice</li>
					<li>Prijepolje</li>
					<li>Arilje</li>
					<li>Požega</li>
					<li>Kosjerić</li>
					<li>Bajina Bašta</li>
					<li>Čajetina</li>
					<li>Priboj</li>
					<li>Prokuplje</li>
					<li>Blace</li>
					<li>Kuršumlija</li>
					<li>Niš</li>
					<li>Merošina</li>
					<li>Aleksinac</li>
					<li>Zemun</li>
					<li>Zvezdara</li>
					<li>Mladenovac</li>
					<li>Lazarevac</li>
					<li>Voždovac</li>
					<li>Palilula</li>
					<li>Savski Venac</li>
					<li>Grocka</li>
					<li>Obrenovac</li>
					<li>Rakovica</li>
					<li>Barajevo</li>
				</ul>
			</div>
			<br></br>
			<br></br>
			<a href="https://www.mre.gov.rs/tekst/sr/2149/projekat-cista-energija-i-energetska-efikasnosti-za-gradjane-surce.php ">
				<p>{t("knowledge-base-21-p9")}</p>
			</a>
		</>
	);
}

export default Article21;

import React, { useEffect, useState } from "react";
import Hero from "../Components/Main/Hero/Hero";
import { LoadScript } from "@react-google-maps/api";
import MapWithControls from "../Components/Main/Map/MapWithControls";
import MapWithADrawingManager from "../Components/Main/MapWithDrawing/MapWithDrawing";
import ChooseCalculationType from "../Components/Main/ChooseCalculationType/ChooseCalculationType";
import "./HomePage.css";
import SubscribeSection from "../Components/Main/SubscribeSection/SubscribeSection";
import SimplifiedCalculation from "../Components/Main/Map/SimplifiedCalculation";
import CalculationHeader from "../Components/Main/Map/CalculationHeader";
import HeroBlank from "../Components/Main/Hero/HeroBlank";
window.all_overlays = [];

function HomePageDevelopment() {
	const [calculationType, setCalculationType] = useState(0);
	const [libraries] = useState(["drawing", "geometry", "places"]);
	const [selectedCountry, setSelectedCountry] = useState("RS");
	const [selectedSupplier, setSelectedSupplier] = useState("XA");
	const [supplier, setSupplier] = useState("gen-i");
	const [center, setCenter] = useState({ lat: 44.797825, lng: 20.469236 });
	const [modalIsOpen, setIsOpen] = useState(false);

	var requestOptions = {
		method: "GET",
	};

	useEffect(() => {}, []);

	return (
		<div className="home-page-container">
			<LoadScript
				loadingElement={<HeroBlank />}
				googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
				libraries={libraries}
			>
				<Hero setIsOpen={setIsOpen} />
				<CalculationHeader></CalculationHeader>
				<ChooseCalculationType
					calculationType={calculationType}
					setCalculationType={setCalculationType}
				></ChooseCalculationType>
				{calculationType === 0 ? (
					<SimplifiedCalculation
						selectedCountry={selectedCountry}
						setSelectedCountry={setSelectedCountry}
						setSelectedSupplier={setSelectedSupplier}
						selectedSupplier={selectedSupplier}
						setSupplier={setSupplier}
						supplier={supplier}
						setCenter={setCenter}
						modalIsOpen={modalIsOpen}
						setIsOpen={setIsOpen}
					></SimplifiedCalculation>
				) : (
					<MapWithControls
						selectedCountry={selectedCountry}
						setSelectedCountry={setSelectedCountry}
						setSelectedSupplier={setSelectedSupplier}
						selectedSupplier={selectedSupplier}
						setSupplier={setSupplier}
						supplier={supplier}
						center={center}
						setCenter={setCenter}
						modalIsOpen={modalIsOpen}
						setIsOpen={setIsOpen}
					></MapWithControls>
				)}
				{/* <Slider activeIndex={2} slidesAtOnce={2} auto hasBullets>
					<div>child 1</div>
					<div>child 2</div>
					<div>child 3</div>
				</Slider> */}

				{/* <SubscribeSection /> */}
			</LoadScript>
		</div>
	);
}

export default HomePageDevelopment;

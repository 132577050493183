import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FirstTitleIcon from "./FirstTitleIcon.js";
import SecondTitleIcon from "./SecondTitleIcon.js";
import ThirdTitleIcon from "./ThirdTitleIcon.js";
import BulbIcon from "./BulbIcon.js";
import Box from "@mui/material/Box";
import Grid from "@mui/system/Unstable_Grid/Grid";
import KnowledgeMenuItem from "./KnowledgeMenuItem.js";
import AccordionHeader from "./AccordionHeader";
import "./KnowledgeMenu.css";
import { useTranslation } from "react-i18next";

const theme = createTheme({
	palette: {
		text: {
			primary: "#958C8C",
			secondary: "#000000",
		},
		icon: {
			primary: "#FD9C61",
			secondary: "#F37021",
		},
	},
	typography: {
		p: {
			fontWeight: "300",
			fontSize: "larger",
		},
		h3: {
			fontWeight: "700",
			color: "#F37021",
			textAlign: "center",
		},
		h5: {
			fontWeight: "700",
		},
	},
	overrides: {
		MuiAccordion: {
			root: {
				"&.Mui-expanded": {
					margin: "0",
				},
			},
			expanded: {
				"& .MuiAccordionDetails-root": {
					margin: "0",
				},
			},
		},
		MuiAccordionSummary: {
			root: {
				"&.Mui-expanded": {
					margin: "0",
				},
			},
			expanded: {
				"& .MuiAccordionDetails-root": {
					margin: "0",
				},
			},
		},
	},
});

function KnowledgeMenu({ setSelectedId, selectedId, ...props }) {
	const [firstTitle, setFirstTitle] = useState(false);
	const [secondTitle, setSecondTitle] = useState(false);
	const [thirdTitle, setThirdTitle] = useState(false);
	const [forthTitle, setForthTitle] = useState(false);

	const { t } = useTranslation();

	const setTitles = (setTitle, title) => {
		setFirstTitle(false);
		setSecondTitle(false);
		setThirdTitle(false);
		setForthTitle(false);
		setTitle(true);
	};

	return (
		<ThemeProvider theme={theme}>
			<div className="knowledge-menu">
				<Grid
					container
					direction="row"
					alignItems="center"
					sx={{ marginTop: "3rem", marginBottom: "3rem", paddingLeft: "16px" }}
				>
					<Grid item="true">
						<Box
							sx={{
								backgroundColor: "transparent",
								display: "flex",
								alignItems: "center",
							}}
						>
							<BulbIcon sx={{ color: "#F37021", fontSize: "3.5rem" }} />
						</Box>
					</Grid>
					<Grid item="true">
						<Typography variant="h3" sx={{ ml: 2 }}>
							{t("knowledge-base")}
						</Typography>
					</Grid>
				</Grid>
				{/* FIRST TITLE */}
				<Accordion
					sx={{ mt: 1, mb: 1 }}
					className={theme.expanded}
					{...props}
					expanded={firstTitle}
				>
					<AccordionHeader
						TitleIcon={FirstTitleIcon}
						title={firstTitle}
						setTitle={setFirstTitle}
						setTitles={setTitles}
						text={t("knowledge-menu-2")}
						setSelectedId={setSelectedId}
						selectedId={selectedId}
						id={5}
					></AccordionHeader>
					<AccordionDetails>
						<Typography sx={{ color: "text.secondary" }}>
							<ul>
								<KnowledgeMenuItem
									text={t("knowledge-menu-2-1")}
									setSelectedId={setSelectedId}
									selectedId={selectedId}
									id={6}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-2-2")}
									setSelectedId={setSelectedId}
									selectedId={selectedId}
									id={7}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-2-3")}
									setSelectedId={setSelectedId}
									selectedId={selectedId}
									id={8}
								></KnowledgeMenuItem>
							</ul>
						</Typography>
					</AccordionDetails>
				</Accordion>

				{/* SECOND TITLE */}
				<Accordion
					sx={{ mt: 1, mb: 1, border: "none" }}
					className={theme.AccordionRoot}
					{...props}
					expanded={thirdTitle}
				>
					<AccordionHeader
						TitleIcon={ThirdTitleIcon}
						title={thirdTitle}
						setTitle={setThirdTitle}
						text={t("knowledge-menu-3")}
						setSelectedId={setSelectedId}
						selectedId={selectedId}
						setTitles={setTitles}
						id={13}
					></AccordionHeader>
					<AccordionDetails>
						<Typography sx={{ color: "text.secondary" }}>
							<ul>
								<KnowledgeMenuItem
									text={t("knowledge-menu-3-1")}
									setSelectedId={setSelectedId}
									id={9}
									selectedId={selectedId}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-3-2")}
									setSelectedId={setSelectedId}
									id={10}
									selectedId={selectedId}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-3-3")}
									setSelectedId={setSelectedId}
									id={11}
									selectedId={selectedId}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-3-4")}
									setSelectedId={setSelectedId}
									id={12}
									selectedId={selectedId}
								></KnowledgeMenuItem>
							</ul>
						</Typography>
					</AccordionDetails>
				</Accordion>

				{/* THIRD TITLE */}
				<Accordion
					sx={{ mt: 1, mb: 1 }}
					className={theme.expanded}
					{...props}
					expanded={secondTitle}
				>
					<AccordionHeader
						TitleIcon={SecondTitleIcon}
						title={secondTitle}
						setTitle={setSecondTitle}
						setTitles={setTitles}
						text={t("knowledge-menu-1")}
						setSelectedId={setSelectedId}
						selectedId={selectedId}
						id={0}
					></AccordionHeader>
					<AccordionDetails>
						<Typography sx={{ color: "text.secondary" }}>
							<ul>
								<KnowledgeMenuItem
									text={t("knowledge-menu-1-1")}
									setSelectedId={setSelectedId}
									selectedId={selectedId}
									id={1}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-1-2")}
									setSelectedId={setSelectedId}
									selectedId={selectedId}
									id={2}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-1-3")}
									setSelectedId={setSelectedId}
									selectedId={selectedId}
									id={3}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-1-4")}
									setSelectedId={setSelectedId}
									selectedId={selectedId}
									id={4}
								></KnowledgeMenuItem>
							</ul>
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion
					sx={{ mt: 1, mb: 1, border: "none" }}
					className={theme.AccordionRoot}
					{...props}
					expanded={forthTitle}
				>
					<AccordionHeader
						TitleIcon={ThirdTitleIcon}
						title={forthTitle}
						setTitle={setForthTitle}
						text={t("knowledge-menu-4")}
						setSelectedId={setSelectedId}
						selectedId={selectedId}
						setTitles={setTitles}
						id={14}
					></AccordionHeader>
					<AccordionDetails>
						<Typography sx={{ color: "text.secondary" }}>
							<ul>
								<KnowledgeMenuItem
									text={t("knowledge-menu-4-1")}
									setSelectedId={setSelectedId}
									id={15}
									selectedId={selectedId}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-4-2")}
									setSelectedId={setSelectedId}
									id={16}
									selectedId={selectedId}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-4-3")}
									setSelectedId={setSelectedId}
									id={17}
									selectedId={selectedId}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-4-4")}
									setSelectedId={setSelectedId}
									id={18}
									selectedId={selectedId}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-4-5")}
									setSelectedId={setSelectedId}
									id={19}
									selectedId={selectedId}
								></KnowledgeMenuItem>
								<KnowledgeMenuItem
									text={t("knowledge-menu-4-6")}
									setSelectedId={setSelectedId}
									id={20}
									selectedId={selectedId}
								></KnowledgeMenuItem>
							</ul>
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		</ThemeProvider>
	);
}

export default KnowledgeMenu;

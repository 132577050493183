import {
	GoogleMap,
	LoadScript,
	DrawingManager,
	DirectionsRenderer,
	Polyline,
} from "@react-google-maps/api";
import React, { useState } from "react";
import mapStyleArray from "./mapStyleArray";

const MapWithDrawing = ({
	measuringArea,
	setMesuringArea,
	changeRoofTopArea,
	setAllCoordinates,
	setPathCoordinates,
	pathCoordinates,
	center,
	setCenter,
}) => {
	const [libraries] = useState(["drawing", "geometry", "places"]);
	const [map, setMap] = useState(null);

	// use this path to make roof bottom line

	const mapContainerStyle = {
		height: "100%",
		width: "100%",
	};
	const [zoom, setZoom] = useState(7);

	function mapOptionsCreator(map) {
		return {
			scrollwheel: false,
			zoomControlOptions: {
				position: map.ControlPosition.RIGHT_CENTER,
				style: map.ZoomControlStyle.SMALL,
			},
			mapTypeControlOptions: {
				position: map.ControlPosition.BOTTOM_RIGHT, // this makes the map type control disappear
			},
			draggable: false,
			rotateControl: false,
			scaleControl: false,
			streetViewControl: false,
			panControl: false,
		};
	}

	const setRedLine = () => {
		var coordinates = window.all_overlays[0].overlay.getPath().getArray();
		if (coordinates[0].lat() && coordinates[1].lat())
			setPathCoordinates([
				{ lat: coordinates[0].lat(), lng: coordinates[0].lng() },
				{ lat: coordinates[1].lat(), lng: coordinates[1].lng() },
			]);
	};

	var polyOptions = {
		strokeWeight: 2,
		fillOpacity: 0.3,
		editable: true,
		strokeColor: "#ff6b00",
		fillColor: "#ff6b00",
	};

	const changeOfPolygon = (e) => {
		var coordinates = e.overlay.getPath().getArray();
		var coordinateObject = [];
		var area = window.google.maps.geometry.spherical.computeArea(
			e.overlay.getPath()
		);
		changeRoofTopArea(parseFloat(area).toFixed(0));
		coordinates.map((coordinate) => {
			coordinateObject.push({ lng: coordinate.lng(), lat: coordinate.lat() });
		});
		setAllCoordinates(coordinateObject);

		setMesuringArea(null);
	};

	const changeZoom = () => {
		if (map) {
			setZoom(map.getZoom());
		}
	};
	const changeCenter = () => {
		if (map) {
			setCenter(map.getCenter());
		}
	};

	const onPolygonComplete = (e) => {
		var coordinates = e.overlay.getPath().getArray();

		var coordinateObject = [];
		var area = window.google.maps.geometry.spherical.computeArea(
			e.overlay.getPath()
		);
		changeRoofTopArea(parseFloat(area).toFixed(0));
		coordinates.map((coordinate) => {
			coordinateObject.push({ lng: coordinate.lng(), lat: coordinate.lat() });
		});
		setAllCoordinates(coordinateObject);

		setMesuringArea(null);
		window.all_overlays.push(e);

		setRedLine();

		window.google.maps.event.addListener(
			e.overlay.getPath(),
			"set_at",
			function () {
				changeOfPolygon(window.all_overlays[0]);
				setRedLine();
			}
		);

		window.google.maps.event.addListener(
			e.overlay.getPath(),
			"insert_at",
			function () {
				changeOfPolygon(window.all_overlays[0]);
				setRedLine();
			}
		);

		window.google.maps.event.addListener(
			e.overlay.getPath(),
			"remove_at",
			function () {
				changeOfPolygon(window.all_overlays[0]);
				setRedLine();
			}
		);
	};
	return (
		<div
			style={{ height: "100%", width: "100%" }}
			className="map-container-width"
			id="simple-map"
		>
			<GoogleMap
				onLoad={(map) => {
					setMap(map);
				}}
				mapContainerStyle={mapContainerStyle}
				zoom={zoom}
				center={center}
				defaultOptions={{
					fullscreenControl: true,
					zoomControl: true,
					disableDefaultUI: true,
				}}
				options={{
					rotateControl: false,
					mapTypeId: "hybrid",
					tilt: 0,
				}}
				onZoomChanged={changeZoom}
				onCenterChanged={changeCenter}
			>
				<Polyline
					path={pathCoordinates}
					geodesic={false}
					options={{
						strokeColor: "#ff2527",
						strokeOpacity: 1,
						strokeWeight: 7,
					}}
					draggable={false}
					editable={false}
				/>
				<DrawingManager
					onOverlayComplete={onPolygonComplete}
					drawingMode={measuringArea}
					options={{
						polygonOptions: polyOptions,
						drawingControl: true,
						drawingControlOptions: {
							drawingModes: [],
						},
					}}
				/>

				<DirectionsRenderer
					option={{ preserveViewport: true }}
				></DirectionsRenderer>
			</GoogleMap>
		</div>
	);
};

export default MapWithDrawing;

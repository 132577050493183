import { storage, storageRef } from "./firebase";
import toast from "react-hot-toast";
import getDownloadUrl from "./getDownloadUrl";

const listFilesFromFolder = async (folder) => {
	try {
		// var listRef = storage.child('ProjectFiles').child(folder);
		var listRef = storageRef.child("ProjectFiles").child(folder);

		// Find all the prefixes and items.
		var filesArray = listRef
			.listAll()
			.then(async (res) => {
				var files = await Promise.all(
					res.items.map(async (itemRef) => {
						var downloadUrl = await getDownloadUrl(itemRef).then();
						var fileExt = itemRef.name.split(".").pop();
						return {
							fileName: itemRef.name,
							bucket: itemRef.bucket,
							fullPath: itemRef.fullPath,
							downloadUrl: downloadUrl,
							fileExtension: fileExt,
						};
					})
				);

				return files;
			})
			.catch((error) => {
				alert("Getting failes failed");
			});

		return filesArray;
	} catch (error) {
		alert("Getting failes failed");
	}
};

export default listFilesFromFolder;

import React from "react";
import { useTranslation } from "react-i18next";

function CalculationHeader() {
	const { t } = useTranslation();

	return (
		<div className="map-with-controls-header" id="map-with-controls-id">
			<h1>{t("map_with_controls_get_started")}</h1>
			<h3>
				{t("map_with_controls_you_want")}
				<span className="orangeletters">{t("map_with_controls_invest")} </span>
				{t("map_with_controls_in_solar")}
				<span className="orangeletters">
					{t("map_with_controls_profitable")}
				</span>
				?
			</h3>
			<h3>
				{t("map_with_controls_follow_these")}
				<span className="orangeletters">
					{t("map_with_controls_easy_steps")}
				</span>
				{t("map_with_controls_to_find_out")}
			</h3>
			<div className="triangle-bottom"></div>
		</div>
	);
}

export default CalculationHeader;

import { duration } from "@material-ui/core";
import toast from "react-hot-toast";

async function postDataBearer(url = "", data = {}, showSuccess = true) {
	var token = localStorage.getItem("token");

	async function getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) {
			return parts.pop().split(";").shift();
		} else {
			return "Loading...";
		}
	}

	const checkCookieForLanguage = async () => {
		return await getCookie("i18next");
	};

	const currentLanguage = await checkLoading();

	async function checkLoading() {
		const language = await checkCookieForLanguage();

		if (language == "sr") {
			return "Učitava se...";
		} else if (language == "en") {
			return "Loading...";
		} else if (language == "hr") {
			return "Učitava se...";
		} else if (language == "si") {
			return "Nalaganje..";
		}
	}
	if (token == undefined || token == null) {
		return "Unothorized";
	}

	const bearer = "Bearer " + token;
	// Default options are marked with *
	const response = await toast.promise(
		fetch(url, {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			headers: {
				"Content-Type": "application/json",
				// 'Accept': 'application/json',
				Authorization: bearer,
				"Accept-Language": await checkCookieForLanguage(),
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: JSON.stringify(data), // body data type must match "Content-Type" header
		})
			.catch((error) => {
				return error;
			})
			.then(async (data) => {
				if (data.status != 200) {
					const newData = await data.json();
					throw Error(newData.message);
				} else {
					return data.json();
				}
			}),
		{
			loading: `${currentLanguage}`,
			success: (data) => ` ${data.message}`,
			error: (err) => ` ${err.toString()}`,
		},
		{
			success: {
				style: {
					display: showSuccess ? "flex" : "none",
				},
			},
		}
	);
	return response; // parses JSON response into native JavaScript objects
}

export default postDataBearer;

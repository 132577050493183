import React,{useState} from "react";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Box from "@material-ui/core/Box";
import './GiveSunRating.css'

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6b00"
  },
  iconHover: {
    color: "#ff6b00"
  }
})(Rating);

export default function GiveSunRating({changeContractorRating,changeDesignerRating,type,rating}) {

  const setRating = (newValue)=>{
      if (type=='contractor'){
        changeContractorRating(newValue)
      }
      if(type=='designer'){
        changeDesignerRating(newValue)
      }
  }

  return (
    <div style={{width:"50%", margin:'0 auto'}} className='give-sun-rating-container'>
      <Box component="fieldset" borderColor="transparent">
        <StyledRating
          size='largeW'
           name={type}
          value={rating}
          getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
          precision={0.5}
          icon={<WbSunnyIcon fontSize="large"/>}
          onChange={(event, newValue) => {
          setRating(newValue);
  }}
        />
      </Box>
    </div>
  );
}

import React from "react";
import "./Hero.css";
import { Button } from "../Buttons/Button";
import PerkItem from "./PerkItem";
import { faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faDraftingCompass } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function HeroBlank() {
	const { t } = useTranslation();

	return (
		<>
			<section className="hero-welcome">
				<div className="right-hero-shadow"></div>
				<div className="bottom-hero-shadow"></div>

				<div className="perks-container">
					<PerkItem
						text={t("hero_perk_1")}
						icon={faMapMarked}
						delay={0.2}
					></PerkItem>
					<PerkItem
						text={t("hero_perk_2")}
						icon={faPiggyBank}
						delay={0.4}
					></PerkItem>
					<PerkItem
						text={t("hero_perk_3")}
						icon={faDraftingCompass}
						delay={0.6}
					></PerkItem>
					<PerkItem
						text={t("hero_perk_4")}
						icon={faMoneyBillWave}
						delay={0.8}
					></PerkItem>
					<PerkItem
						text={t("hero_perk_5")}
						icon={faChartLine}
						delay={1}
					></PerkItem>
				</div>
			</section>
		</>
	);
}

export default HeroBlank;

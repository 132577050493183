import React from "react";
import { useTranslation } from "react-i18next";
import Image1 from "../../../../Assets/Images/articleImages/Article 17-1.png";
import Image2 from "../../../../Assets/Images/articleImages/Article 17-2.png";
import Image3 from "../../../../Assets/Images/articleImages/Article 17-3.png";
import Image4 from "../../../../Assets/Images/articleImages/Article 17-4.png";
import Image5 from "../../../../Assets/Images/articleImages/Article 17-5.png";
import { Helmet } from "react-helmet";

import ArticleImage from "../ArticleImage";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-17-h")}</h1>
			<p>{t("knowledge-base-17-p1")}</p>
			<h3>{t("knowledge-base-17-h1")}</h3>
			<p>{t("knowledge-base-17-p2")}</p>
			<ArticleImage src={Image1} heading={t("image17-1")}></ArticleImage>

			<h3>{t("knowledge-base-17-h2")}</h3>
			<p>{t("knowledge-base-17-p3")}</p>
			<ArticleImage src={Image2} heading={t("image17-2")}></ArticleImage>

			<h3>{t("knowledge-base-17-h4")}</h3>
			<p>{t("knowledge-base-17-p5")}</p>
			<ArticleImage src={Image3} heading={t("image17-3")}></ArticleImage>

			<h3>{t("knowledge-base-17-h5")}</h3>
			<p>{t("knowledge-base-17-p6")}</p>
			<ArticleImage src={Image4} heading={t("image17-4")}></ArticleImage>

			<h3>{t("knowledge-base-17-h6")}</h3>
			<p>{t("knowledge-base-17-p7")}</p>
			<ArticleImage src={Image5} heading={t("image17-5")}></ArticleImage>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-17-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-17-p1")} />
				<title>{t("knowledge-base-17-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-17-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article2;

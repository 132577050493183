import React from "react";
import { useTranslation } from "react-i18next";
import Table1 from "../Tables/Table1";
import { Helmet } from "react-helmet";

function Article4() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-4-h")}</h1>
			<p>{t("knowledge-base-4-p1")}</p>
			<br></br>
			<br></br>
			<p className="indend">
				{t("knowledge-base-4-p2")}
				<br></br>
				{t("knowledge-base-4-p3")}
				<br></br>
				{t("knowledge-base-4-p4")}
			</p>
			<br></br>
			<Table1></Table1>
			<br></br>
			<p>{t("knowledge-base-4-p5")}</p>
			<br></br>
			<br></br>
			<p>{t("knowledge-base-4-p6")}</p>
			<br></br>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-4-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-4-p1")} />
				<title>{t("knowledge-base-4-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-4-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article4;

import React from 'react'
import MapWithControls  from '../Components/Main/Map/MapWithControls'
import NotFound from '../Components/Main/Hero/NotFound'
import './HomePage.css'

function Hero404() {
    return (
        <div className="home-page-container">
            <NotFound/>
        </div>
    )
}

export default Hero404

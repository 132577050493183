import React from 'react'
import NavBar from './NavBar'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function Header() {
    return (
        <>
            <Switch>
                <NavBar path="/" exact backgroundStyle="transparent" ></NavBar>
                <NavBar path="/log-in" backgroundStyle="transparent" ></NavBar>
                <NavBar path="/register" backgroundStyle="transparent" ></NavBar>
                <NavBar path="/profile" backgroundStyle="transparent" ></NavBar>
                <NavBar path="/contractor" backgroundStyle="transparent" ></NavBar>
                <NavBar path="/project" backgroundStyle="transparent" ></NavBar>
                <NavBar path="/available-projects" backgroundStyle="transparent" ></NavBar>
                <NavBar path="/successful-registration" backgroundStyle="transparent" ></NavBar>
                <NavBar path="/successful-login" backgroundStyle="transparent" ></NavBar>
                <NavBar path="/loading" backgroundStyle="transparent" ></NavBar>
                <NavBar backgroundStyle="transparent" ></NavBar>
            </Switch>
        </>
    )
}

export default Header

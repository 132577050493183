import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

export default function RegulativeIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg
				id="Layer_1"
				x="0px"
				y="0px"
				viewBox="0 0 24 24"
				width="24"
				height="24"
			>
				<g
					id="g12"
					transform="matrix(0.48804634,0,0,0.48804634,-1.7390363,-0.93916773)"
				>
					<rect
						x="28.299999"
						y="30"
						fill="#958C8C"
						width="16.200001"
						height="6.3000002"
						id="rect4"
					/>
					<rect
						x="28.299999"
						y="21.700001"
						fill="#958C8C"
						width="22.9"
						height="6.3000002"
						id="rect6"
					/>
					<rect
						x="28.299999"
						y="13.4"
						fill="#958C8C"
						width="22.9"
						height="6.3000002"
						id="rect8"
					/>
					<path
						fill="#958C8C"
						d="M 34.9,44.9 H 11.4 V 28 H 24.6 V 21.7 H 18 v -8.4 c 0,-1.2 0.4,-2.3 1.1,-3.2 0.7,-0.9 1.8,-1.5 2.9,-1.8 1.2,-0.2 2.4,-0.1 3.4,0.5 1,0.5 1.9,1.4 2.3,2.5 l 0.6,1.4 5.8,-2.5 -0.6,-1.4 C 32.5,6.4 30.6,4.4 28.3,3.2 26,2 23.3,1.6 20.7,2.2 18.3,2.6 16,4 14.3,6 c -1.7,2 -2.6,4.6 -2.6,7.2 v 8.4 H 9.9 c -1.3,0 -2.5,0.5 -3.4,1.4 -0.9,0.9 -1.4,2.1 -1.4,3.4 v 19.9 c 0,1.3 0.5,2.5 1.4,3.4 0.9,0.9 2.1,1.4 3.4,1.4 h 26.5 c 1.3,0 2.5,-0.5 3.4,-1.4 0.9,-0.9 1.4,-2.1 1.4,-3.4 v -8.1 h -6.3 z"
						id="path10"
					/>
				</g>
			</svg>
		</SvgIcon>
	);
}

import React from "react";
import Modal from "react-modal";
import MakeAnOfferForm from "../../Forms/MakeAnOfferForm";

const customStyles = {
	content: {
		height: "90vh",
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0",
		borderRadius: "20px",
	},
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function MakeAnOfferModal({
	modalIsOpen,
	closeModal,
	openModal,
	projectId,
	designIndicator,
	installationIndicator,
	offerData,
	setRefresh,
	name,
	valute,
}) {
	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				// onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Example Modal"
			>
				<MakeAnOfferForm
					closeModal={closeModal}
					projectId={projectId}
					designIndicator={designIndicator}
					installationIndicator={installationIndicator}
					offerData={offerData}
					setRefresh={setRefresh}
					name={name}
					valute={valute}
				></MakeAnOfferForm>
			</Modal>
		</div>
	);
}

export default MakeAnOfferModal;

import React, { createContext, useEffect, useState, useContext } from "react";
import postData from "./../../Services/BackComunication/postData";
import axios from "axios";
import serverUrl from "../../Services/BackComunication/serverUrl";
import { lighten } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const AuthContext = createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	let history = useHistory();
	// posibble states loged-out investor contractor
	const [currentUser, setCurrentUser] = useState("loged-out");
	const [userData, setUserData] = useState({
		name: "",
		surname: "",
		photo: "",
		id: "",
		company_name: "",
	});

	useEffect(() => {
		var token = localStorage.getItem("token");
		var user = JSON.parse(localStorage.getItem("user"));
		if (user != null && user != undefined) {
			setUserData(user);
		}
		if (token != null && token != undefined) {
			if (localStorage.getItem("role") == "investor") {
				setCurrentUser("investor");
			} else if (localStorage.getItem("role") == "contractor") {
				setCurrentUser("contractor");
			} else {
				setCurrentUser("loged-out");
			}
		}
	}, []);

	const signup = async (values, redirect) => {
		const dataObject = await postData(serverUrl + "/login", {
			email: values.email,
			password: values.password,
		})
			.catch((error) => {
				console.error(error.message);
				return error.message;
			})
			.then((data) => {
				if (data.status == 200) {
					localStorage.setItem("token", data.body.token);
					localStorage.setItem("user", JSON.stringify(data.body));

					setUserData({
						name: data.body.name,
						surname: data.body.surname,
						photo: data.body.photo,
						id: data.body.id,
						company_name: data.body.company_name,
					});

					if (data.body.role == 2) {
						setCurrentUser("contractor");
						localStorage.setItem("role", "contractor");
						history.push("/contractor");
					} else if (data.body.role == 1) {
						setCurrentUser("investor");
						localStorage.setItem("role", "investor");

						if (redirect == "/") {
							history.push(redirect);
							setTimeout(() => {
								var scrollToNew = document.getElementById("estimation-results");
								scrollToNew.scrollIntoView({
									behavior: "smooth",
									block: "end",
								});
							}, 500);
						} else {
							history.push("/profile");
						}
					} else {
						setCurrentUser("loged-out");
						localStorage.setItem("role", "loged-out");
					}
				} else {
				}
				return data;
			});
		return dataObject;
	};

	const changeToken = (values) => {
		localStorage.setItem("token", values.token);
		localStorage.setItem("user", JSON.stringify(values));
		setUserData({
			name: values.name,
			surname: values.surname,
			photo: values.photo,
			id: values.id,
			company_name: values.company_name,
		});
	};

	const signOut = () => {
		localStorage.setItem("token", "undefined");
		localStorage.removeItem("user");
		localStorage.setItem("role", "loged-out");
		setUserData({});
		setCurrentUser("loged-out");
		localStorage.removeItem("project");
	};

	const value = {
		currentUser,
		userData,
		signup,
		signOut,
		setCurrentUser,
		changeToken,
	};
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

export default function BulbIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg
				version="1.1"
				id="Layer_1"
				x="0px"
				y="0px"
				viewBox="0 0 24 24"
				width="24"
				height="24"
			>
				<g
					id="g18"
					transform="matrix(0.10050251,0,0,0.10050251,-8.2121607,-7.7085413)"
				>
					<path
						class="st0"
						d="M 254.1,152.2 C 246.3,141 231.3,127.6 204,126.9 c -0.9,0 -1.9,0 -2.9,0 -1,0 -2,0 -2.9,0 -27.3,0.6 -42.3,14.1 -50.1,25.3 -7.8,11.2 -9.9,22.1 -10.2,24.2 -5.4,29.3 16.4,55 18,56.7 5.8,6.8 8.4,23.7 8.3,37.4 0,3.5 1.3,6.9 3.8,9.4 2.5,2.5 5.8,3.9 9.4,3.9 h 23.7 23.7 c 3.5,0 6.9,-1.4 9.4,-3.9 2.5,-2.5 3.9,-5.8 3.8,-9.4 -0.1,-13.7 2.5,-30.6 8.3,-37.4 1.6,-1.8 23.4,-27.4 18,-56.7 -0.3,-2.1 -2.4,-13 -10.2,-24.2 z m -25,65.8 v 0 c -9.9,11.4 -12.9,29.9 -13.8,42.8 H 201.1 186.9 C 186,247.9 183,229.4 173.2,218.1 l -0.1,-0.1 c -0.1,-0.2 -16.2,-18.8 -12.6,-37.5 l 0.1,-0.4 c 0,0 0.3,-2 1.4,-4.9 1,-2.9 2.8,-6.7 5.5,-10.5 6.9,-9.5 17.5,-14.5 31.3,-14.8 0.2,0 1,0 2.4,0 1.3,-0.1 2.2,0 2.4,0 13.8,0.3 24.4,5.3 31.3,14.8 2.8,3.8 4.5,7.6 5.5,10.5 1,2.9 1.4,4.9 1.4,4.9 l 0.1,0.4 c 3.3,18.7 -12.7,37.3 -12.8,37.5 z"
						id="path4"
					/>
					<polygon
						class="st0"
						points="201.1,116.6 213.6,116.6 213.6,76.7 201.1,76.7 188.6,76.7 188.6,116.6 "
						id="polygon6"
					/>
					<rect
						x="278.39999"
						y="185.7"
						class="st0"
						width="39.900002"
						height="25"
						id="rect8"
					/>

					<rect
						x="260.20001"
						y="121.9"
						transform="matrix(0.7071,-0.7071,0.7071,0.7071,-12.9498,237.4509)"
						class="st0"
						width="39.900002"
						height="25"
						id="rect10"
					/>
					<rect
						x="83.900002"
						y="185.7"
						class="st0"
						width="39.900002"
						height="25"
						id="rect12"
					/>

					<rect
						x="109.6"
						y="114.4"
						transform="matrix(0.7071,-0.7071,0.7071,0.7071,-59.2556,125.6586)"
						class="st0"
						width="25"
						height="39.900002"
						id="rect14"
					/>
					<polygon
						class="st0"
						points="201.1,290.5 174.1,290.5 174.1,315.5 201.1,315.5 228.1,315.5 228.1,290.5 "
						id="polygon16"
					/>
				</g>
			</svg>
		</SvgIcon>
	);
}

import React, { useState, useEffect } from "react";
import PercentageCircle from "./PercentageCircle";
import solarLogo from "../../../Assets/Images/roofko-icon.svg";
import { useTranslation } from "react-i18next";

function PercentageCircleGrade({ radius, percent, borderWidth, color }) {
	const [refresh, setRefresh] = useState(true);
	useEffect(() => {
		setRefresh(!refresh);
	}, [percent]);

	const { t } = useTranslation();

	return (
		<div style={{ margin: "0 auto", padding: "2% 0" }}>
			<PercentageCircle
				radius={radius}
				borderWidth={borderWidth}
				percent={percent}
				color="#ff6b00"
			>
				<img width="64" src={solarLogo} alt="Roofko score logo" />
				<h5>
					<span className="percent">{percent}%</span>
				</h5>
				<h5>{t("roofko_score")}</h5>
			</PercentageCircle>
		</div>
	);
}

export default PercentageCircleGrade;

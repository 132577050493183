import React from 'react'
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0',
    borderRadius:'20px'
  }
};


// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function EditDataModal({closeModal,modalIsOpen,ModalComponent,submitForm,id,setRefresh,profileData}) {

    return (
        <div>
            <Modal
                classes={{content:customStyles.content,Overley:customStyles.Overley}}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal" >
                <ModalComponent closeModal={closeModal} submitForm={submitForm} id={id} setRefresh={setRefresh} profileData={profileData}></ModalComponent>
            </Modal>
        </div>
    )
}

export default EditDataModal

import React from "react";
import { useTranslation } from "react-i18next";
import Image from "../../../../Assets/Images/articleImages/Article 7-1.png";
import ImageENG from "../../../../Assets/Images/articleImages/Article 7-1ENG.png";
import { Helmet } from "react-helmet";

import ArticleImage from "../ArticleImage";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-7-h")}</h1>
			<p>
				{t("knowledge-base-7-p2")}
				<ArticleImage
					src={Image}
					srcENG={ImageENG}
					margin="narrow"
				></ArticleImage>

				{t("knowledge-base-7-p3")}
				<br></br>
				<br></br>
				{t("knowledge-base-7-p4")}
				<br></br>
				<br></br>
				{t("knowledge-base-7-p5")}
				<br></br>
				<br></br>
				{t("knowledge-base-7-p6")}
				<br></br>
				<br></br>
				{t("knowledge-base-7-p7")}
			</p>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-7-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-7-p1")} />
				<title>{t("knowledge-base-7-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-7-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article2;

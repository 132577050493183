import React from "react";
import "./ChooseCalculationType.css";
import { useTranslation } from "react-i18next";

function ChooseCalculationType({ calculationType, setCalculationType }) {
	const { t } = useTranslation();
	return (
		<div className="choose-calculation-type-section">
			<div className="choose-calculation">
				<div
					className={
						calculationType === 0
							? "calculation-button calculation-button-left chosen"
							: "calculation-button calculation-button-left notchosen"
					}
					onClick={() => {
						setCalculationType(0);
					}}
				>
					<h2>{t("basic-calculation")}</h2>
					<p className="additional-calculation-explenation">
						{t("basic-calculation-explanation1")}
						<span className="orangeletters">
							{t("basic-calculation-explanation2")}
						</span>
						{t("basic-calculation-explanation3")}
						<span className="orangeletters">
							{t("basic-calculation-explanation4")}
						</span>
						{t("basic-calculation-explanation5")}
					</p>
				</div>
				<div
					className={
						calculationType === 0
							? "calculation-button calculation-button-right notchosen"
							: "calculation-button calculation-button-right chosen"
					}
					onClick={() => {
						setCalculationType(1);
					}}
				>
					<h2>{t("advanced-calculation")}</h2>
					<p className="additional-calculation-explenation">
						{t("advanced-calculation-explanation1")}
						<span className="orangeletters">
							{t("advanced-calculation-explanation2")}
						</span>
						{t("advanced-calculation-explanation3")}
						<span className="orangeletters">
							{t("advanced-calculation-explanation4")}
						</span>
						{t("advanced-calculation-explanation5")}
					</p>
				</div>
			</div>
		</div>
	);
}

export default ChooseCalculationType;

import React from "react";
import { useTranslation } from "react-i18next";
import Table2 from "../Tables/Table2";
import { Helmet } from "react-helmet";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-8-h")}</h1>
			<p>{t("knowledge-base-8-p1")}</p>
			<br></br>
			<Table2></Table2>
			<br></br>
			<h3>{t("knowledge-base-8-h1")}</h3>
			<p>
				<i>{t("knowledge-base-8-p2")}</i>
				<br></br>
				<br></br>
				{t("knowledge-base-8-p3")}
				<br></br>
				<br></br>
				<i>{t("knowledge-base-8-p4")}</i>
				<br></br>
				<br></br>
				{t("knowledge-base-8-p5")}
				<br></br>
				<br></br>
				<i>{t("knowledge-base-8-p6")}</i>
				<br></br>
				<br></br>
				{t("knowledge-base-8-p7")}
				<br></br>
				<br></br>
				<i>{t("knowledge-base-8-p8")}</i>
				<br></br>
				<br></br>
				{t("knowledge-base-8-p9")}
			</p>
			<h3>{t("knowledge-base-8-h2")}</h3>
			<p>
				<i>{t("knowledge-base-8-p10")}</i>
				<br></br>
				<br></br>
				{t("knowledge-base-8-p11")}
				<br></br>
				<br></br>
				<i>{t("knowledge-base-8-p12")}</i>
				<br></br>
				<br></br>
				{t("knowledge-base-8-p13")}
				<br></br>
				<br></br>
				<i>{t("knowledge-base-8-p14")}</i>
				<br></br>
				<br></br>
				{t("knowledge-base-8-p15")}
			</p>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-8-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-8-p1")} />
				<title>{t("knowledge-base-8-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-8-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article2;

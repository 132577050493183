import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import "./ControlButton.css";
import "../../../Assets/fonts/fonticonIcons/flaticon.css";

import { MapWithControlsContext } from "./MapWithControls";

const useStyles = (props) =>
	makeStyles((theme) => ({
		button: {
			margin: theme.spacing(1),
			letterSpacing: "2px",
			fontSize: "1.1rem",
			fontWeight: "400",
			color: props.color,
			backgroundColor: props.backgroundColor,
			border: props.color,
			borderStyle: "solid",
			borderWidth: "0px",
			"&:hover": {
				backgroundColor: props.color,
				color: props.backgroundColor,
			},
		},
	}));

export default function ControlButton({
	backgroundColor,
	color,
	text,
	flaticon,
	onClick,
	disabled = false,
}) {
	var props = { backgroundColor, color };

	const classes = useStyles(props)();
	return (
		<Button
			variant="contained"
			size="medium"
			className={classes.button}
			color="inherit"
			startIcon={
				<i
					className={flaticon}
					style={{ display: "flex", alignItems: "center", fontSize: "30px" }}
				></i>
			}
			disabled={disabled}
			onTouchTap={onClick}
			onClick={onClick}
		>
			{text}
		</Button>
	);
}

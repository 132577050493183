import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { Autocomplete } from "@react-google-maps/api";
import "../SlopeSlider/SlopeSlider.css";
import roofImage from "../../../Assets/Images/roofOrientation.svg";
import { useTranslation } from "react-i18next";

import "./OrientationSlider.css";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "80%",
		margin: "auto",
	},
	margin: {
		height: theme.spacing(3),
	},
}));

const roofImages = {
	0: roofImage,
};

function valuetext(value) {
	return `${value}°C`;
}

export default function SlopeSlider({ orientation, setOrientation }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const marks = [
		{
			value: 0,
			label: t("north"),
		},
		{
			value: 45,
			label: t("northwest"),
		},

		{
			value: 90,
			label: t("west"),
		},
		{
			value: 135,
			label: t("southwest"),
		},
		{
			value: 180,
			label: t("south"),
		},
		{
			value: 225,
			label: t("southeast"),
		},
		{
			value: 270,
			label: t("east"),
		},
		{
			value: 315,
			label: t("northeast"),
		},
		{
			value: 360,
			label: t("north"),
		},
	];

	return (
		<div className="slider-and-image-container slider-and-image-container-orientation">
			<div className="roof-img-with-slope">
				<div style={{ width: "100%", margin: "0 auto" }}>
					<div className="rotatingRoof">
						<img
							style={{
								width: "75%",
								margin: "0 auto",
								transform: "rotate(" + orientation + "deg)",
							}}
							src={roofImages[0]}
							alt="Roof slope image"
							id="turn"
						></img>
					</div>
				</div>
			</div>
			<div
				className={classes.root}
				style={{
					boxShadow: "2px 3px 3px #00000029",
					border: "1px solid #70707033",
					marginRight: "5%",
					borderRadius: "10px 10px 10px 10px",
					marginTop: "3%",
					padding: "2% 5%",
				}}
			>
				<Slider
					defaultValue={orientation}
					getAriaValueText={valuetext}
					aria-labelledby="discrete-slider-custom"
					step={5}
					valueLabelDisplay="auto"
					marks={marks}
					max={360}
					min={0}
					onChange={(e, value) => {
						setOrientation(360 - value);
					}}
					onChangeCommitted={(e, value) => {
						setOrientation(360 - value);
					}}
					valueLabelFormat={(x) => {
						return x + "°";
					}}
				/>
			</div>
		</div>
	);
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { Autocomplete } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "80%",
		margin: "auto",
	},
	margin: {
		height: theme.spacing(3),
	},
}));

export default function ScaleSlider({
	projectData,
	setSliderInvestment,
	valute,
}) {
	const classes = useStyles();
	var maxInvestment = projectData.project.max_estimated_investment;
	const [newInvestment, setNewInvestment] = useState(maxInvestment);

	const marks = () => {
		if (projectData.project.country_code == "rs") {
			return [
				{
					value: maxInvestment * 0.2,
					label: `${((maxInvestment / 1000) * 0.2).toPrecision(4)}k`,
				},

				{
					value: maxInvestment * 0.6,
					label: `${((maxInvestment / 1000) * 0.6).toPrecision(4)}k`,
				},
				{
					value: maxInvestment * 1,
					label: `${((maxInvestment / 1000) * 1).toPrecision(4)}k`,
				},
			];
		} else if (projectData.project.country_code == "me") {
			return [
				{
					value: maxInvestment * 0.2,
					label: `${((maxInvestment / 1000) * 0.2).toPrecision(2)}k`,
				},
				{
					value: maxInvestment * 0.4,
					label: `${((maxInvestment / 1000) * 0.4).toPrecision(2)}k`,
				},
				{
					value: maxInvestment * 0.6,
					label: `${((maxInvestment / 1000) * 0.6).toPrecision(2)}k`,
				},
				{
					value: maxInvestment * 0.8,
					label: `${((maxInvestment / 1000) * 0.8).toPrecision(2)}k`,
				},
				{
					value: maxInvestment * 1,
					label: `${((maxInvestment / 1000) * 1).toPrecision(2)}k`,
				},
			];
		} else {
			return [
				{
					value: maxInvestment * 0.2,
					label: `${((maxInvestment / 1000) * 0.2).toPrecision(2)}k`,
				},
				{
					value: maxInvestment * 0.4,
					label: `${((maxInvestment / 1000) * 0.4).toPrecision(2)}k`,
				},
				{
					value: maxInvestment * 0.6,
					label: `${((maxInvestment / 1000) * 0.6).toPrecision(2)}k`,
				},
				{
					value: maxInvestment * 0.8,
					label: `${((maxInvestment / 1000) * 0.8).toPrecision(2)}k`,
				},
				{
					value: maxInvestment * 1,
					label: `${((maxInvestment / 1000) * 1).toPrecision(2)}k`,
				},
			];
		}
	};

	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<Typography id="range-slider" gutterBottom>
				{t("scale_your_system")}
			</Typography>

			<Slider
				defaultValue={projectData.project.max_specified_investment}
				aria-labelledby="discrete-slider-custom"
				step={1000}
				valueLabelDisplay="auto"
				max={maxInvestment}
				onChange={(e, value) => {
					setNewInvestment(value);
					setSliderInvestment(value);
				}}
				onChangeCommitted={(e, value) => {
					setNewInvestment(value);
					setSliderInvestment(value);
				}}
				valueLabelFormat={(x) => {
					return x / 1000 + "k";
				}}
				marks={marks()}
				style={{ color: "#007bff" }}
			/>
		</div>
	);
}

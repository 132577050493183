import i18next from "i18next";
import React, { useEffect, useState, useRef } from "react";
import ReactFlagsSelect from "react-flags-select";
import "./LanguagePicker.css";
import { useTranslation } from "react-i18next";
import { useLanguagePicker } from "../../../LanguagePickerContext";
import { useLocation } from "react-router-dom";

function LanguagePicker() {
	const { activeLanguage, setActiveLanguage } = useLanguagePicker();
	const { t } = useTranslation();
	function getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) {
			return parts.pop().split(";").shift();
		} else {
			return "sr";
		}
	}

	useEffect(() => {
		setActiveLanguage(getCookie("i18next"));
	}, []);

	const codeCharts = {
		RS: "sr",
		GB: "en",
		// HR: "hr",
		// SI: "si",
	};

	const languageCharts = {
		sr: "RS",
		en: "GB",
		// hr: "HR",
		// si: "SI",
	};

	return (
		<div className="language-select-div">
			{/* <ReactLanguageSelect
				ref={userLanguage}
				defaultLanguage={activeLanguage}
				languages={["sr", "en", "ch"]}
				showSelectedLabel={true}
				showOptionLabel={true}
				selectedSize={16}
				customLabels={{ en: "   EN", me: "ME", ch: "HR" }}
				onSelect={LanguageChange}
			/> */}
			<ReactFlagsSelect
				onSelect={(code) => {
					i18next.changeLanguage(codeCharts[code]);
					setActiveLanguage(codeCharts[code]);
				}}
				showOptionLabel={false}
				countries={["RS", "GB"]}
				showSelectedLabel={false}
				customLabels={{
					RS: t("montenegro"),
					GB: t("serbia"),
				}}
				selected={languageCharts[activeLanguage]}
			/>
		</div>
	);
}

export default LanguagePicker;

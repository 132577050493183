import React, { useEffect, useState } from "react";
import "./general.css";
import Footer from "./Components/Footer/Footer";
import {
	LanguagePickerProvider,
	useLanguagePicker,
} from "./LanguagePickerContext";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import LanguageRedirect from "./LanguageRedirect";
import Header from "./Components/Header/Header";
import { Router, Switch, Route } from "react-router-dom";
import HomePageDevelopment from "./Pages/HomePageDevelopment";
import LogIn from "./Pages/LogIn";
import Register from "./Pages/Register";
import ProfilePage from "./Pages/ProfilePage";
import ContractorPage from "./Pages/ContractorPage";
import ProfilePageLimited from "./Pages/ProfilePageLimited";
import ContractorPageLimited from "./Pages/ContractorPageLimited";
import ProjectPage from "./Pages/ProjectPage";
import AvailableProjectsPage from "./Pages/AvailableProjectsPage";
import { ProtectedRoute } from "./Components/ProtectedRoute/ProtectedRoute";
import { useAuth } from "./Components/Contexts/auth-context";
import LoadingPage from "./Components/LoadingPage/LoadingPage";
import TermsOfUse from "./Pages/TermsOfUse";
import Hero404 from "./Pages/Hero404";
import UnderConstructionPage from "./Pages/UnderConstructionPage";
import { Toaster } from "react-hot-toast";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";
import PageOfKnowledge from "./Pages/PageOfKnowledge";
import FinancingPage from "./Pages/FinancingPage";
import RegulativePage from "./Pages/RegulativePage";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function App() {
	const { activeLanguage, setActiveLanguage } = useLanguagePicker();

	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();

	const [isInitializingFromURLApp, setIsInitializingFromURLApp] =
		useState(false);

	useEffect(() => {
		// Extract language from URL
		const lang = location.pathname.split("/")[1]; // Assumes language is the first segment of the path
		if (lang && lang !== i18n.language) {
			if (lang === "en" || lang === "si" || lang === "hr" || lang === "sr") {
				i18n.changeLanguage(lang);
			}
		}
	}, []);

	function getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) {
			return parts.pop().split(";").shift();
		} else {
			return "en";
		}
	}
	useEffect(() => {
		const parts = window.location.pathname.split("/");

		// Update the language part with the new language
		if (parts[1] !== i18n.language) {
			parts[1] = i18n.language;

			// Join the parts back into a URL and navigate to it
			const newUrl = parts.join("/");

			if (parts[2] === undefined) {
				parts[1] = "";

				const newUrl = parts.join("/");

				history.push(newUrl);
			} else {
				history.push(newUrl);
			}
		}

		setActiveLanguage(getCookie("i18next"));
	}, [i18n.language]);

	const { setCurrentUser } = useAuth();
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);

	var jwt = localStorage.getItem("token");

	if (jwt != "undefined" && jwt != null) {
	}

	return (
		<div>
			<Header />
			<Switch>
				<Route path="/" exact component={HomePageDevelopment} />
				{/* 
				<ProtectedRoute
					path="/:lang(sr|en|si|hr)/log-in"
					component={LogIn}
					usersAllowed={["loged-out", "investor", "contractor"]}
				/>
				<Route exact path="/log-in" component={LanguageRedirect} /> */}

				<ProtectedRoute
					path="/:lang(sr|en|si|hr)/base/:id"
					component={PageOfKnowledge}
					usersAllowed={["loged-out", "investor", "contractor"]}
				/>
				<Route exact path="/base/:id" component={LanguageRedirect} />

				<ProtectedRoute
					path="/:lang(sr|en|si|hr)/financing/:id"
					component={FinancingPage}
					usersAllowed={["loged-out", "investor", "contractor"]}
				/>
				<Route exact path="/financing/:id" component={LanguageRedirect} />

				<ProtectedRoute
					path="/:lang(sr|en|si|hr)/regulative/:id"
					component={RegulativePage}
					usersAllowed={["loged-out", "investor", "contractor"]}
				/>
				<Route exact path="/regulative/:id" component={LanguageRedirect} />

				{/* <ProtectedRoute
					path="/:lang(sr|en|si|hr)/register"
					component={Register}
					usersAllowed={["loged-out"]}
				/>
				<Route exact path="/register" component={LanguageRedirect} /> */}
				{/* 
				<ProtectedRoute
					path="/:lang(sr|en|si|hr)/profile"
					exact
					component={ProfilePage}
					usersAllowed={["investor", "contractor"]}
				/>
				<Route exact path="/profile" component={LanguageRedirect} /> */}

				{/* <ProtectedRoute
					path="/:lang(sr|en|si|hr)/contractor"
					exact
					component={ContractorPage}
					usersAllowed={["contractor"]}
				/>
				<Route exact path="/contractor" component={LanguageRedirect} /> */}

				{/* <ProtectedRoute
					path="/:lang(sr|en|si|hr)/profile/:id"
					component={ProfilePageLimited}
					usersAllowed={["investor", "contractor"]}
				/>
				<Route exact path="/profile/:id" component={LanguageRedirect} /> */}
				{/* 
				<ProtectedRoute
					path="/:lang(sr|en|si|hr)/contractor/:id"
					component={ContractorPageLimited}
					usersAllowed={["contractor", "investor"]}
				/>
				<Route exact path="/contractor/:id" component={LanguageRedirect} /> */}
				{/* 
				<Route
					path="/:lang(sr|en|si|hr)/project/:id/:limited"
					component={ProjectPage}
					usersAllowed={["contractor", "investor"]}
				/> */}

				<Route
					exact
					path="/project/:id/:limited"
					component={LanguageRedirect}
				/>
				<Route
					path="/:lang(sr|en|si|hr)/loading"
					component={LoadingPage}
					usersAllowed={["contractor", "investor", "loged-out"]}
				/>
				<Route exact path="/loading" component={LanguageRedirect} />

				{/* <ProtectedRoute
					path="/:lang(sr|en|si|hr)/available-projects"
					component={AvailableProjectsPage}
					usersAllowed={["investor", "contractor"]}
				/>
				<Route exact path="/available-projects" component={LanguageRedirect} /> */}

				<ProtectedRoute
					path="/:lang(sr|en|si|hr)/terms-of-use"
					component={TermsOfUse}
					usersAllowed={["investor", "contractor", "loged-out"]}
				/>
				<Route exact path="/terms-of-use" component={LanguageRedirect} />

				<ProtectedRoute
					path="/:lang(sr|en|si|hr)/privacy-policy"
					component={PrivacyPolicyPage}
					usersAllowed={["investor", "contractor", "loged-out"]}
				/>
				<Route exact path="/privacy-policy" component={LanguageRedirect} />

				<Route component={Hero404} />
			</Switch>
			<Toaster />
			<Footer />
			<Helmet>
				<meta property="og:title" content={"Roofko-" + t("hero_headline")} />
				<meta property="og:description" content={t("page-meta-description")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
				<title>{"Roofko-" + t("hero_headline")}</title>
				<meta name="description" content={t("page-meta-description")} />
			</Helmet>
		</div>
	);
}

export default App;

import React,{useState,useEffect,useImperativeHandle, forwardRef} from 'react'
import './OutputData.css'


const  OutputData=({unit,value,label,type='normal'})=>{

    return (
        <div className="output-data-row">
            <div className="output-and-label">
                <label className='output-label'>{label}</label>
                <div  className={type=="special"?"nogrid output-fields":'output-fields'}>
                <input type="text" style={{borderRadius:type=="special"?"0.4rem 0.4rem 0.4rem 0.4rem":'0.4rem 0 0 0.4rem'}} className="output-field" placeholder={value} disabled size={5} ></input><span className="unit" style={{display:type=="special"?"none":'block'}}>{type=='m'?<>{unit}<sup>2</sup></>:unit}</span>
                </div>
            </div>
      </div>
    )
}

export default OutputData

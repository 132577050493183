import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./Components/Contexts/auth-context";
import ScrollToTheTop from "./Components/ScrollToTheTop/ScrollToTheTop";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LoadingPage from "./Components/LoadingPage/LoadingPage";
import { LanguagePickerProvider } from "./LanguagePickerContext";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
	dsn: "https://0670a2aeef024b9db464703ce8fb40da@o1183079.ingest.sentry.io/6300141",
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		fallbackLng: "sr",
		supportedLngs: ["sr", "en", "hr", "si"],
		detection: {
			// order: ["path", "navigator", "htmlTag", "cookie", "localStorage", "path"],
			order: [
				"cookie",
				"navigator",
				"querystring",
				"htmlTag",
				"localStorage",
				"path",
			],
			caches: ["cookie"],
		},
		backend: { loadPath: "/assets/locales/{{lng}}/translation8.json" },
	});

ReactDOM.render(
	<Suspense fallback={LoadingPage}>
		<LanguagePickerProvider>
			<Router>
				<ScrollToTheTop />
				<AuthProvider>
					<React.StrictMode>
						<App />
					</React.StrictMode>
				</AuthProvider>
			</Router>
		</LanguagePickerProvider>
	</Suspense>,
	document.getElementById("root")
);

import React, { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import {
	geocodeByAddress,
	geocodeByPlaceId,
	getLatLng,
} from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import "./CityInput.css";
import googleImage from "../../../Assets/Images/google_on_white.png";
import profileImage from "../../../Assets/Images/default_profile.png";

function CityInput({
	address,
	setAddress,
	setEstimationNotAllowed,
	selectedCountry,
}) {
	const [libraries] = useState(["places"]);
	const handleSelect = async (value) => {
		setAddress(value);

		setEstimationNotAllowed(false);
	};

	const { t } = useTranslation();
	const searchOptions = {
		types: [],
		componentRestrictions: { country: selectedCountry.toLowerCase() },
	};

	const onChangeAddress = (e) => {
		setAddress(e);
		setEstimationNotAllowed(true);
	};

	return (
		<div>
			<PlacesAutocomplete
				value={address}
				onChange={onChangeAddress}
				onSelect={handleSelect}
				searchOptions={searchOptions}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
					return (
						<div className="output-and-label">
							<label className="otput-label bill-output-label">
								{t("output_area_title")}
							</label>
							{/* <div className="output-fields"> */}
							<div className="">
								<input
									{...getInputProps({ placeholder: t("type_your_city") })}
									className="output-field output-field-bill input-field-important"
									type="text"
								/>
							</div>
							<div style={{ width: "90%" }}>
								{loading ? <div>Loading..</div> : null}

								{suggestions.map((suggestion, i) => {
									const style = suggestion.active
										? { backgroundColor: "#ffce3b", cursor: "pointer" }
										: { backgroundColor: "#ffffff", cursor: "pointer" };
									return (
										<>
											<div {...getSuggestionItemProps(suggestion, { style })}>
												<p className="city-suggestion">
													{suggestion.description}
												</p>
											</div>
											{suggestions.length === i + 1 ? (
												<div {...getSuggestionItemProps(suggestion, { style })}>
													<img
														className="attribution-google"
														src={googleImage}
														alt="attribution google"
														style={{ display: "inline-block" }}
													></img>
												</div>
											) : (
												""
											)}
										</>
									);
								})}
							</div>
						</div>
					);
				}}
			</PlacesAutocomplete>
		</div>
	);
}

export default CityInput;

import React, { useContext, useState } from "react";
import "./MesureButtons.css";
import { MapWithControlsContext } from "./MapWithControls";
import ControlButton from "./ControlButton";
import { useTranslation } from "react-i18next";

function MesureButtons() {
	var { startMeasureTool, resetMeasureTool, measuring } = useContext(
		MapWithControlsContext
	);
	const [mesuring, setMesuring] = useState(false);

	const { t } = useTranslation();

	return (
		<div className="control-buttons-container">
			<ControlButton
				backgroundColor="#ff6b00"
				color="white"
				text={t("measure_button_measure")}
				flaticon="flaticon-ruler"
				onClick={() => (startMeasureTool(), setMesuring(true))}
				disabled={mesuring}
			></ControlButton>
			<ControlButton
				backgroundColor="#17a2b8"
				color="white"
				text={t("measure_button_reset")}
				flaticon="flaticon-rubber"
				onClick={() => (resetMeasureTool(), setMesuring(true))}
				disabled={!mesuring}
			></ControlButton>
		</div>
	);
}

export default MesureButtons;

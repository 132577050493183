import React, { useState, useEffect } from "react";
import "./ProjectTimeLine.css";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import ControlButton from "../Map/ControlButton";
import editProjectStatus from "../../../Services/BackComunication/editProjectStatus";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: "14px 16px",
		marginBottom: "10px",
	},
	secondaryTail: {
		backgroundColor: theme.palette.secondary.main,
	},
	timeline: {
		margin: "4% 0",
		padding: "0 0 4% 0",
		borderBottom: "1px solid gray",
	},
	finishedIcon: {
		backgroundColor: "#1F6B5E",
	},
}));

export default function CustomizedTimeline({
	type = "normal",
	projectData,
	setRefresh,
	refresh,
}) {
	const classes = useStyles();

	// for now there are 6 levels of time line
	const [timelineLevel, setTimelineLevel] = useState(
		projectData.project.project_status
	);
	const maxTimelineLevel = 6;
	const [TimeLineObject, setTimeLineObject] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
	]);

	useEffect(() => {
		setTimelineLevel(projectData.project.project_status);
		createTimeLineObject(maxTimelineLevel, timelineLevel);
	}, [timelineLevel, projectData, refresh]);
	// create array to determine style for every item in timeline

	const createTimeLineObject = (maxTimelineLevel, timelineLevel) => {
		var tempTimeLineObject = [];
		for (let i = 0; i < maxTimelineLevel; i++) {
			if (i <= timelineLevel) {
				tempTimeLineObject[i] = true;
			} else {
				tempTimeLineObject[i] = false;
			}
		}
		setTimeLineObject(tempTimeLineObject);
	};

	const decreaseStatus = async () => {
		var data = await editProjectStatus(
			timelineLevel - 1,
			projectData.project.id
		);
		if (data.status == 200) {
			setRefresh();
		}
	};

	const increaseStatus = async () => {
		var data = await editProjectStatus(
			timelineLevel + 1,
			projectData.project.id
		);
		if (data.status == 200) {
			setRefresh();
		}
	};

	const { t } = useTranslation();

	return (
		<>
			<div className="project-timeline-container white-section">
				<h3 className="project-section-header">{t("timeline_title")}</h3>

				<Timeline align="alternate" className={classes.timeline}>
					<TimelineItem>
						<TimelineOppositeContent></TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineDot
								className={
									TimeLineObject[0]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							>
								{TimeLineObject[0] ? (
									<DoneIcon fontSize="large" />
								) : (
									<WatchLaterOutlinedIcon fontSize="large" />
								)}
							</TimelineDot>
							<TimelineConnector
								className={
									TimeLineObject[0]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							/>
						</TimelineSeparator>
						<TimelineContent>
							<Paper elevation={3} className={classes.paper}>
								<div className="timeline-grid">
									<h3 className="ordinal-number">01</h3>
									<h3 className="timeline-item-text">{t("timeline_0")}</h3>
								</div>
							</Paper>
							<span>&nbsp;&nbsp;</span>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineOppositeContent></TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineDot
								className={
									TimeLineObject[1]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							>
								{TimeLineObject[1] ? (
									<DoneIcon fontSize="large" />
								) : (
									<WatchLaterOutlinedIcon fontSize="large" />
								)}
							</TimelineDot>
							<TimelineConnector
								className={
									TimeLineObject[1]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							/>
						</TimelineSeparator>
						<TimelineContent>
							<Paper elevation={3} className={classes.paper}>
								<div className="timeline-grid">
									<h3 className="ordinal-number">02</h3>
									<h3 className="timeline-item-text">{t("timeline_1")}</h3>
								</div>
							</Paper>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineSeparator>
							<TimelineDot
								className={
									TimeLineObject[2]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							>
								{TimeLineObject[2] ? (
									<DoneIcon fontSize="large" />
								) : (
									<WatchLaterOutlinedIcon fontSize="large" />
								)}
							</TimelineDot>
							<TimelineConnector
								className={
									TimeLineObject[2]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							/>
						</TimelineSeparator>
						<TimelineContent>
							<Paper elevation={3} className={classes.paper}>
								<div className="timeline-grid">
									<h3 className="ordinal-number">03</h3>
									<h3 className="timeline-item-text">{t("timeline_2")}</h3>
								</div>
							</Paper>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineSeparator>
							<TimelineDot
								className={
									TimeLineObject[3]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							>
								{TimeLineObject[3] ? (
									<DoneIcon fontSize="large" />
								) : (
									<WatchLaterOutlinedIcon fontSize="large" />
								)}
							</TimelineDot>
							<TimelineConnector
								className={
									TimeLineObject[3]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							/>
						</TimelineSeparator>
						<TimelineContent>
							<Paper elevation={3} className={classes.paper}>
								<div className="timeline-grid">
									<h3 className="ordinal-number">04</h3>
									<h3 className="timeline-item-text">{t("timeline_3")}</h3>
								</div>
							</Paper>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineSeparator>
							<TimelineDot
								className={
									TimeLineObject[4]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							>
								{TimeLineObject[4] ? (
									<DoneIcon fontSize="large" />
								) : (
									<WatchLaterOutlinedIcon fontSize="large" />
								)}
							</TimelineDot>
							<TimelineConnector
								className={
									TimeLineObject[4]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							/>
						</TimelineSeparator>
						<TimelineContent>
							<Paper elevation={3} className={classes.paper}>
								<div className="timeline-grid">
									<h3 className="ordinal-number">05</h3>
									<h3 className="timeline-item-text">{t("timeline_4")}</h3>
								</div>
							</Paper>
						</TimelineContent>
					</TimelineItem>

					<TimelineItem>
						<TimelineSeparator>
							<TimelineDot
								className={
									TimeLineObject[5]
										? classes.finishedIcon
										: classes.unfinishedIcon
								}
							>
								{TimeLineObject[5] ? (
									<DoneIcon fontSize="large" />
								) : (
									<WatchLaterOutlinedIcon fontSize="large" />
								)}
							</TimelineDot>
						</TimelineSeparator>
						<TimelineContent>
							<Paper elevation={3} className={classes.paper}>
								<div className="timeline-grid">
									<h3 className="ordinal-number">06</h3>
									<h3 className="timeline-item-text">{t("timeline_5")}</h3>
								</div>
							</Paper>
						</TimelineContent>
					</TimelineItem>
				</Timeline>
				{type == "designer" ? (
					<>
						{timelineLevel > 1 ? (
							<div className="timeline-control-buttons">
								{timelineLevel < 3 ? (
									<div></div>
								) : (
									<ControlButton
										backgroundColor="#bdbdbd"
										color="white"
										text={t("timeline_decrese")}
										onClick={decreaseStatus}
									></ControlButton>
								)}
								{timelineLevel > 4 ? (
									<div></div>
								) : (
									<ControlButton
										backgroundColor="#1F6B5E"
										color="white"
										text={t("timeline_increase")}
										onClick={increaseStatus}
									></ControlButton>
								)}
							</div>
						) : (
							""
						)}
					</>
				) : (
					""
				)}
			</div>
		</>
	);
}

import React, { useState } from "react";
import RegulativeFile from "./RegulativeFile";
import "./RegulativeFiles.css";
import { useTranslation } from "react-i18next";

function RegulativeFiles() {
	const { t } = useTranslation();

	const [regulativeFiles, setRegulativeFiles] = useState([
		{
			heading: t("regulativefile-1-h"),
			description: t("regulativefile-1-description"),
			extension: "pdf",
			src: "https://wapi.gov.me/download/e731d4ca-a833-4230-8a68-527ee3e72d9e?version=1.0 ",
			type: "acrobat",
		},
		{
			heading: t("regulativefile-2-h"),
			description: t("regulativefile-2-description"),
			extension: "pdf",
			src: "https://wapi.gov.me/download-preview/1603a1b7-1090-4e00-b7fd-e5e97607f030?version=1.0",
			type: "acrobat",
		},
		{
			heading: t("regulativefile-3-h"),
			description: t("regulativefile-3-description"),
			extension: "docx",
			src: "https://wapi.gov.me/download/a4c728d0-1490-4bfa-9004-0e8c3d213bfd?version=1.0 ",
			type: "document",
		},
	]);
	return (
		<>
			<div className="regulative-files-table">
				<RegulativeFile
					heading={t("regulativefile-1-h")}
					description={t("regulativefile-1-description")}
					src="https://wapi.gov.me/download/e731d4ca-a833-4230-8a68-527ee3e72d9e?version=1.0 "
					extension="pdf"
					type="acrobat"
				></RegulativeFile>
				<RegulativeFile
					heading={t("regulativefile-2-h")}
					description={t("regulativefile-2-description")}
					src="https://wapi.gov.me/download-preview/1603a1b7-1090-4e00-b7fd-e5e97607f030?version=1.0"
					extension="pdf"
					type="acrobat"
				></RegulativeFile>
				<RegulativeFile
					heading={t("regulativefile-3-h")}
					description={t("regulativefile-3-description")}
					src="https://wapi.gov.me/download/a4c728d0-1490-4bfa-9004-0e8c3d213bfd?version=1.0 "
					extension="docx"
					type="document"
				></RegulativeFile>
			</div>
		</>
	);
}

export default RegulativeFiles;

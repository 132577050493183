import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

export default function FirstTitleIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24" height="24" viewBox="0 0 24 24">
				<g clipPath="url(#clip0_201_33)" id="g22" transform="scale(0.47058824)">
					<path
						d="M 28.0537,13.777 V 9 H 22.968 v 4.777 c 1.689,-0.2816 3.4129,-0.2816 5.1019,0 z"
						id="path4"
					/>
					<path
						d="M 22.968,37.2285 V 42 h 5.1019 v -4.7715 c -1.689,0.2816 -3.4129,0.2816 -5.1019,0 z"
						id="path6"
					/>
					<path
						d="m 16.7774,16.9887 c 0.6303,-0.6589 1.344,-1.2325 2.1231,-1.7061 l -3.2496,-3.2496 -3.6071,3.6071 3.2497,3.2496 c 0.4251,-0.685 0.9226,-1.3224 1.4839,-1.901 z"
						id="path8"
					/>
					<path
						d="m 34.2279,34.0059 c -0.6331,0.6558 -1.3464,1.229 -2.123,1.7061 l 3.2496,3.2496 3.6071,-3.6071 -3.2496,-3.2496 c -0.4296,0.6819 -0.9268,1.3188 -1.4841,1.901 z"
						id="path10"
					/>
					<path
						d="m 13.5224,25.5027 c 0.003,-0.8575 0.0827,-1.7131 0.2383,-2.5564 H 9.0108 v 5.102 h 4.7499 C 13.606,27.2085 13.5263,26.3566 13.5224,25.5027 Z"
						id="path12"
					/>
					<path
						d="m 37.2501,22.9463 c 0.3107,1.6865 0.3107,3.4155 0,5.102 h 4.7553 v -5.102 z"
						id="path14"
					/>
					<path
						d="m 15.2989,32.0995 -3.2496,3.2496 3.607,3.6071 3.2497,-3.2497 c -0.7767,-0.477 -1.49,-1.0503 -2.1231,-1.706 -0.5589,-0.5809 -1.0562,-1.2179 -1.484,-1.901 z"
						id="path16"
					/>
					<path
						d="m 35.7065,18.906 3.2497,-3.2497 -3.6071,-3.6071 -3.2497,3.2497 c 0.7791,0.4736 1.4929,1.0471 2.1231,1.706 0.5598,0.5801 1.0571,1.2173 1.484,1.9011 z"
						id="path18"
					/>
					<path
						d="m 25.5027,15.1905 c -3.1467,0 -5.6923,0.9858 -7.5554,2.9301 -1.8038,2.0349 -2.7998,4.6601 -2.7998,7.3794 0,2.7193 0.996,5.3444 2.7998,7.3794 1.8631,1.9497 4.4032,2.9355 7.5554,2.9355 3.1521,0 5.6923,-0.9858 7.55,-2.9355 1.8071,-2.0334 2.8053,-4.6591 2.8053,-7.3794 0,-2.7203 -0.9982,-5.3461 -2.8053,-7.3794 -1.8632,-1.9443 -4.4033,-2.9301 -7.55,-2.9301 z m 3.8508,14.4501 c -1.0325,0.9999 -2.4135,1.559 -3.8508,1.559 -1.4374,0 -2.8183,-0.5591 -3.8508,-1.559 -0.9096,-1.1887 -1.4025,-2.6438 -1.4025,-4.1406 0,-1.4968 0.4929,-2.9519 1.4025,-4.1406 1.0325,-0.9999 2.4134,-1.559 3.8508,-1.559 1.4373,0 2.8183,0.5591 3.8508,1.559 0.9096,1.1887 1.4025,2.6438 1.4025,4.1406 0,1.4968 -0.4929,2.9519 -1.4025,4.1406 z"
						id="path20"
					/>
				</g>
				<defs id="defs27">
					<clipPath id="clip0_201_33">
						<rect
							width="33"
							height="33"
							transform="translate(9,9)"
							id="rect24"
							x="0"
							y="0"
						/>
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>
	);
}

import React from "react";
import { useTranslation } from "react-i18next";
import ArticleImage from "../ArticleImage";
import Image1 from "../../../../Assets/Images/articleImages/Article 1-1.png";
import Image1ENG from "../../../../Assets/Images/articleImages/Article 1-1ENG.png";
import { Helmet } from "react-helmet";

function Article1() {
	const { t } = useTranslation();

	return (
		<>
			<>
				<h1>{t("knowledge-base-1-h")}</h1>
				<p>
					{t("knowledge-base-1-p2")}

					<ArticleImage
						srcENG={Image1ENG}
						src={Image1}
						heading={t("image1-1")}
					></ArticleImage>
					{t("knowledge-base-1-p3")}
					<br></br>
					<br></br>
					{t("knowledge-base-1-p4")}
					<br></br>
					<br></br>
					{t("knowledge-base-1-p5")}
					<br></br>
					<br></br>
					{t("knowledge-base-1-p6")}
				</p>
				<Helmet>
					<meta
						property="og:title"
						content={t("knowledge-base-1-h") + "- Roofko"}
					/>
					<meta property="og:description" content={t("knowledge-base-1-p2")} />
					<meta property="og:image" content="URL of News Thumbnail Image" />
					<title>{t("knowledge-base-1-h") + "- Roofko"}</title>
					<meta name="description" content={t("knowledge-base-1-p2")} />
				</Helmet>
			</>
		</>
	);
}

export default Article1;

import React from "react";
import "./Hero.css";
import { Button } from "../Buttons/Button";
import PerkItem from "./PerkItem";
import { faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faDraftingCompass } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function Hero({ setIsOpen }) {
	const { t } = useTranslation();

	return (
		<>
			<section className="hero-welcome">
				<div className="right-hero-shadow"></div>
				<div className="bottom-hero-shadow"></div>
				<div className="upper-hero-shadow"></div>

				<div className="hero-container">
					{/* <h2 className="hero-sub-headline">
            <span className="hero-first-letter">W</span>elcome to
          </h2> */}
					<h1 className="hero-headline">{t("hero_headline")}</h1>
					<div className="hero-headline-description">
						{/* <div className="hero-separator">
              <div className="hero-line hero-line-left" />
              <div className="hero-asterisk">
                <GiSolarPower />
              </div>
              <div className="hero-line hero-line-right" />
            </div> */}
						<div className="hero-single-animation">
							{/* <div className="top-line">
                ANALYZE BUILD <span>PROFIT</span>
              </div>
              <div>
                  <h3 className="hero-bellow-text">Let's find out the full solar potential of your roof and build it in no time!</h3>
              </div> */}
							<a
								tabIndex="1"
								onClick={() => {
									document
										.getElementById("map-with-controls-id")
										.scrollIntoView({ behavior: "smooth" });
								}}
							>
								<Button buttonStyle="btn--primary">
									<span className="find-roof-button">
										{t("find_roof_button")}
									</span>
								</Button>
							</a>
							<a
								tabIndex="1"
								onClick={() => {
									setIsOpen(true);
								}}
							>
								<Button buttonStyle="btn--outline">
									<span className="find-roof-button">{t("get_offer")}</span>
								</Button>
							</a>
						</div>
					</div>
				</div>

				<div className="perks-container">
					<PerkItem
						text={t("hero_perk_1")}
						icon={faMapMarked}
						delay={0.2}
					></PerkItem>
					<PerkItem
						text={t("hero_perk_2")}
						icon={faPiggyBank}
						delay={0.4}
					></PerkItem>
					<PerkItem
						text={t("hero_perk_3")}
						icon={faDraftingCompass}
						delay={0.6}
					></PerkItem>
					<PerkItem
						text={t("hero_perk_4")}
						icon={faMoneyBillWave}
						delay={0.8}
					></PerkItem>
					<PerkItem
						text={t("hero_perk_5")}
						icon={faChartLine}
						delay={1}
					></PerkItem>
				</div>
			</section>
		</>
	);
}

export default Hero;

import React, { useState } from "react";
import LoadingPage from "../Components/LoadingPage/LoadingPage";
import PageHero from "../Components/Main/PageHero/PageHero";
import Box from "@mui/material/Box";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Typography from "@mui/material/Typography";
import CountrySelect from "../Components/Main/Map/CountrySelect";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import "./RegulativePage.css";
import "./PageOfKnowledge.css";
import RegulativeIcon from "../Assets/Images/RegulativeIcon";
import RegulativeFiles from "../Components/Main/RegulativeFiles/RegulativeFiles";
import { useTranslation } from "react-i18next";

function FinancingPage() {
	var loading = false;
	const { t } = useTranslation();

	const [selectedCountry, setSelectedCountry] = useState("ME");

	const theme = createTheme({
		typography: {
			h3: {
				fontWeight: "700",
				color: "#958C8C",
				textAlign: "center",
				"@media (max-width:750px)": {
					fontSize: "2.5rem", // font size for screens up to 600px
				},
				"@media (max-width:650px)": {
					fontSize: "2rem", // font size for screens up to 600px
				},
				"@media (max-width:450px)": {
					fontSize: "1.5rem", // font size for screens up to 600px
				},
			},
			h5: {
				fontSize: "1.3rem",
			},
		},
	});
	// theme = responsiveFontSizes(theme);

	return (
		<div className="profile-wrapper other-pages">
			{loading ? (
				<LoadingPage></LoadingPage>
			) : (
				<>
					<PageHero></PageHero>
					<ThemeProvider theme={theme}>
						<div className="profile-container">
							<Grid
								container
								direction="row"
								alignItems="center"
								sx={{
									marginTop: "3rem",
									marginBottom: "3rem",
									paddingLeft: "16px",
								}}
							>
								<Grid item="true" sm="auto">
									<Box
										sx={{
											backgroundColor: "transparent",
											display: "flex",
											alignItems: "center",
										}}
									>
										<RegulativeIcon
											sx={{ color: "#958C8C", fontSize: "3.5rem" }}
										/>
									</Box>
								</Grid>
								<Grid item="true" sm="auto">
									<Typography variant="h3" sx={{ ml: 2 }}>
										{t("regulative")}
									</Typography>
								</Grid>
							</Grid>
							{/* <div className="regulative-country-select">
								<div>
									<CountrySelect
										selectedCountry={selectedCountry}
										changeCenter={() => {}}
										setValuteAndBill={() => {}}
									></CountrySelect>
								</div>
								<div className="regulative-country-select-text">
									 <Typography variant="h5" sx={{ ml: 2 }}>
										Odaberite državu kako bi pronašli cjelokupnu regulativu
										vezanu za solarnu energetiku te države
									</Typography> 
								</div>
							</div> */}
							<RegulativeFiles></RegulativeFiles>
						</div>
					</ThemeProvider>
				</>
			)}
		</div>
	);
}

export default FinancingPage;

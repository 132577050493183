import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

export default function SecondTitleIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24" height="24" viewBox="0 0 24 24" version="1.1" id="svg6">
				<path
					d="m 15.030323,11.677754 0.08174,0.08174 0.0816,-0.08188 3.57923,-3.5919227 0.08128,-0.0816 -0.08141,-0.081461 -0.894831,-0.8947846 -0.08155,-0.0816 -0.0816,0.0816 -2.609169,2.6091692 -1.263831,-1.2637846 -0.0816,-0.0816 -0.08155,0.0816 -0.89483,0.8947846 -0.0816,0.0816 0.0816,0.0816 z M 11.711538,9.0576923 V 8.9423077 H 11.596154 5.8846154 5.7692308 v 0.1153846 1.2692307 0.115385 h 0.1153846 5.7115386 0.115384 v -0.115385 z m 6.31237,4.1494617 0.0816,-0.0816 -0.0816,-0.08155 -0.894831,-0.894831 -0.08155,-0.0816 -0.0816,0.0816 -1.562077,1.562077 -1.562077,-1.562077 -0.0816,-0.0816 -0.08155,0.0816 -0.89483,0.894831 -0.0816,0.08155 0.0816,0.0816 1.562077,1.562077 -1.562077,1.562077 -0.0816,0.08155 0.0816,0.0816 0.89483,0.89483 0.08155,0.08155 0.0816,-0.08155 1.562077,-1.562077 1.562077,1.562077 0.0816,0.08155 0.08155,-0.08155 0.894831,-0.89483 0.0816,-0.0816 -0.0816,-0.08155 -1.562077,-1.562077 z m -6.31237,0.927461 V 14.019231 H 11.596154 5.8846154 5.7692308 v 0.115384 1.269231 0.115385 h 0.1153846 5.7115386 0.115384 v -0.115385 z"
					stroke="#ffffff"
					strokeWidth="0.461538"
					id="path4"
				/>
			</svg>
		</SvgIcon>
	);
}

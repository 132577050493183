import React from "react";
import { useTranslation } from "react-i18next";
import Image from "../../../../Assets/Images/articleImages/Article 12-1.jpg";
import ArticleImage from "../ArticleImage";
import { Helmet } from "react-helmet";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-12-h")}</h1>
			<p>
				{t("knowledge-base-12-p1")}
				<br></br>
				<br></br>
				{t("knowledge-base-12-p2")}
				<br></br>
				<br></br>
				{t("knowledge-base-12-p3")}
				<ArticleImage src={Image} heading={t("image12-1")}></ArticleImage>
			</p>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-12-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-12-p1")} />
				<title>{t("knowledge-base-12-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-12-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article2;

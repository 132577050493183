import React,{useState,useEffect,useImperativeHandle, forwardRef} from 'react'
import './OutputArea.css'
import {useTranslation } from "react-i18next";


const  OutputArea=forwardRef((props,ref)=>{
    const [roofArea,setRoofArea]=useState(0);

    const setRoofAreaOutput= (area)=>{
      setRoofArea(area)
    }
    
    useImperativeHandle(ref, () => {
    return {
      setRoofAreaOutput: setRoofAreaOutput
    };
  });

    const { t } = useTranslation();

    return (
      <div className="output-and-label">
        <label className='otput-label'>{t('output_area_title')}</label>
        <div className="output-fields">
          <div className="output-field">{roofArea}</div><span className="unit">&nbsp;m<sup><sup>2</sup></sup>&nbsp;</span>
        </div>
      </div>
    )
})

export default OutputArea

import React from "react";
import { useTranslation } from "react-i18next";
import ArticleImage from "../ArticleImage";
import Image from "../../../../Assets/Images/articleImages/Article 10-1.png";
import ImageENG from "../../../../Assets/Images/articleImages/Article 10-1ENG.png";
import Image2 from "../../../../Assets/Images/articleImages/Article 10-2.png";
import Image3 from "../../../../Assets/Images/articleImages/Article 10-3.jpg";
import { Helmet } from "react-helmet";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-10-h")}</h1>
			<p>{t("knowledge-base-10-p1")}</p>
			<ArticleImage
				src={Image}
				srcENG={ImageENG}
				margin="narrow"
			></ArticleImage>
			<h3>{t("knowledge-base-10-h1")}</h3>
			<p>
				{t("knowledge-base-10-p5")}
				<br></br>
				<br></br>
				{t("knowledge-base-10-p2")}
			</p>
			<ArticleImage src={Image2} heading={t("image10-2")}></ArticleImage>
			<h3>{t("knowledge-base-10-h2")}</h3>
			<p>
				{t("knowledge-base-10-p3")}
				<br></br>
				<br></br>
			</p>
			<ArticleImage src={Image3} heading={t("image10-3")}></ArticleImage>
			<h3>{t("knowledge-base-10-h3")}</h3>
			<p>
				{t("knowledge-base-10-p4")}
				<br></br>
				<br></br>
			</p>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-10-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-10-p1")} />
				<title>{t("knowledge-base-10-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-10-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article2;

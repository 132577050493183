import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	accordion: {
		"&:hover .MuiBox-root": {
			backgroundColor: "#F37021",
		},
		"&:hover .MuiTypography-root": {
			color: "#444",
		},
		transition: "color 0.3s ease-in-out backgroundColor 0.3s ease-in-out",
	},
});

function AccordionHeader({
	TitleIcon,
	title,
	setTitle,
	text,
	setSelectedId,
	setTitles,
	id,
}) {
	const classes = useStyles();

	return (
		<AccordionSummary
			aria-controls="panel1a-content"
			id="panel1a-header"
			onClick={() => {
				setTitles(setTitle, title);
				setSelectedId(id);
			}}
			className={classes.accordion}
		>
			<Grid container direction="row" alignItems="center">
				<Grid item="true">
					<Box
						sx={{
							backgroundColor: title ? "icon.secondary" : "icon.primary",
							display: "flex",
							alignItems: "center",
						}}
						className="MuiBox-root"
					>
						<TitleIcon sx={{ color: "#FFF" }} fontSize="large" />
					</Box>
				</Grid>
				<Grid item="true">
					<Typography
						pl={1}
						sx={{
							color: title ? "text.secondary" : "text.primary",
						}}
						variant="h5"
						className="MuiTypography-root"
					>
						{text}
					</Typography>
				</Grid>
			</Grid>
		</AccordionSummary>
	);
}

export default AccordionHeader;

import { useTranslation } from "react-i18next";

export const useValidateInvestorData = () => {
	const { t } = useTranslation();

	const validateInvestorData = (values) => {
		let errors = {};

		errors.errorsTag = false;

		if (!values.firstname.trim()) {
			errors.firstname = t("validate_investor_first_name");
			errors.errorsTag = true;
		} else if (values.lastname.length < 1 || values.lastname.length > 50) {
			errors.lastname = t("validate_investor_name_length");
			errors.errorsTag = true;
		}

		if (!values.lastname.trim()) {
			errors.lastname = t("validate_investor_lastname");
			errors.errorsTag = true;
		} else if (values.firstname.length < 1 || values.firstname.length > 50) {
			errors.firstname = t("validate_investor_lastname_length");
			errors.errorsTag = true;
		}

		// if (!values.email) {
		// 	if (!values.number) {
		// 		errors.email = t("validate_investor_email_number");
		// 		errors.number = t("validate_investor_email_number");
		// 		errors.errorsTag = true;
		// 	}
		// } else if (!/\S+@\S+\.\S+/.test(values.email)) {
		// 	errors.email = t("validate_investor_email_invalid");
		// 	errors.errorsTag = true;
		// }

		if (!values.number && !values.email) {
			errors.email = t("validate_investor_email_number");
			errors.number = t("validate_investor_email_number");
			errors.errorsTag = true;
		}

		return errors;
	};
	return validateInvestorData;
};

import React from "react";
import "./ArticleImage.css";
import { useTranslation } from "react-i18next";

function ArticleImage({
	src,
	srcENG = "noEnglishImage",
	heading = "",
	margin = "",
}) {
	var { i18n } = useTranslation();

	const currentLanguage = i18n.language;
	// if (srcENG === "noEnglishImage") {
	// 	currentLanguage = "";
	// }

	return (
		<>
			<div class={"image-container" + " " + margin}>
				<img
					src={
						srcENG == "noEnglishImage"
							? src
							: currentLanguage == "en"
							? srcENG
							: src
					}
					alt={heading}
				/>
				{heading != "" ? <p className="center">{heading}</p> : ""}
			</div>
		</>
	);
}

export default ArticleImage;

import React from "react";
import "./ProfilePage.css";
import "./TermsOfUse.css";
import Typography from "@material-ui/core/Typography";
import PageHero from "../Components/Main/PageHero/PageHero";
import { useTranslation } from "react-i18next";
import TermsOfUseElement from "./TermsOfUseElement";

import {
	createTheme,
	responsiveFontSizes,
	ThemeProvider,
} from "@material-ui/core/styles";

let theme = createTheme();
theme = responsiveFontSizes(theme);

function TermsOfUse() {
	const { t } = useTranslation();

	return (
		<div className="profile-wrapper terms-wrapper">
			<>
				<PageHero></PageHero>
				<div className="profile-container">
					<div className="terms-container">
						<ThemeProvider theme={theme}>
							<Typography variant="h2" gutterBottom align="center">
								{t("terms-of-use")}
							</Typography>
							<TermsOfUseElement
								title={t("terms-of-use1")}
								body={t("terms-of-use-body1")}
							></TermsOfUseElement>
							<TermsOfUseElement
								title={t("terms-of-use2")}
								body={t("terms-of-use-body2")}
							></TermsOfUseElement>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body2-2")}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body2-3")}
							</Typography>
							<TermsOfUseElement
								title={t("terms-of-use3")}
								body={t("terms-of-use-body3")}
							></TermsOfUseElement>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body3-2")}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body3-3")}
							</Typography>
							<TermsOfUseElement
								title={t("terms-of-use8")}
								body={t("terms-of-use-body8")}
							></TermsOfUseElement>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body8-2")}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body8-3")}
							</Typography>
							<TermsOfUseElement
								title={t("terms-of-use9")}
								body={t("terms-of-use-body9")}
							></TermsOfUseElement>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body9-2")}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body8-3")}
							</Typography>
							<TermsOfUseElement
								title={t("terms-of-use10")}
								body={t("terms-of-use-body10")}
							></TermsOfUseElement>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body10-2")}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body10-3")}
							</Typography>
							<TermsOfUseElement
								title={t("terms-of-use4")}
								body={t("terms-of-use-body4")}
							></TermsOfUseElement>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body4-2")}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body4-3")}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body4-4")}
							</Typography>
							<TermsOfUseElement
								title={t("terms-of-use5")}
								body={t("terms-of-use-body5")}
							></TermsOfUseElement>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body5-2")}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{t("terms-of-use-body5-3")}
							</Typography>
							<TermsOfUseElement
								title={t("terms-of-use6")}
								body={t("terms-of-use-body6")}
							></TermsOfUseElement>
							<TermsOfUseElement
								title={t("terms-of-use7")}
								body={t("terms-of-use-body7")}
							></TermsOfUseElement>
						</ThemeProvider>
					</div>
				</div>
			</>
		</div>
	);
}

export default TermsOfUse;

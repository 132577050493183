import React from "react";
import "./FloatingMenu.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function FloatingMenu({ button, handleClick }) {
	const path = window.location.pathname;
	const { t } = useTranslation();
	const { i18n } = useTranslation();

	return (
		<div className={button ? "floating-menu" : "floating-menu no-display"}>
			<CustomLink
				href={"/" + i18n.language + "/base/introduction-to-solar-energy"}
				text={t("knowledge-base")}
				path={path}
				handleClick={handleClick}
			></CustomLink>

			<CustomLink
				href={"/" + i18n.language + "/financing/1"}
				text={t("financing")}
				path={path}
				handleClick={handleClick}
			></CustomLink>
		</div>
	);
}

function CustomLink({ href, text, path, handleClick }) {
	return (
		<Link
			to={{
				pathname: href,
			}}
			onClick={handleClick}
		>
			<h6
				className={
					path.replace(/\/[^/]*$/, "") == href.replace(/\/[^/]*$/, "")
						? "active"
						: ""
				}
			>
				{text}
			</h6>
		</Link>
	);
}

export default FloatingMenu;

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import "./ExplenationCard.css";

const styles = {
	media: {
		height: 200,
	},
};

function ExplenationCard(props) {
	const { classes, image, text, text2 = "" } = props;
	return (
		<Card className={classes.card}>
			<CardActionArea>
				<CardMedia className={classes.media} image={image} />
				<CardContent>
					<Typography
						component="p"
						style={{ textAlign: "justify", fontSize: "1.2rem" }}
					>
						{text}
					</Typography>
					<Typography
						component="p"
						style={{
							textAlign: "justify",
							fontSize: "1.2rem",
							marginTop: "3%",
						}}
					>
						{text2}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

ExplenationCard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExplenationCard);

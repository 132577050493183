import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Article2() {
	const { t } = useTranslation();

	return (
		<>
			<h1>{t("knowledge-base-20-h")}</h1>
			<p>
				{t("knowledge-base-20-p1")}
				<br></br>
				<br></br>
				{t("knowledge-base-20-p2")}
			</p>
			<p className="indend">
				<br></br>
				<br></br>
				{t("knowledge-base-20-p3")}
				<br></br>
				{t("knowledge-base-20-p4")}
				<br></br>
				{t("knowledge-base-20-p5")}
				<br></br>
				<br></br>
			</p>
			<p>
				{t("knowledge-base-20-p6")}
				<br></br>
				<br></br>
				{t("knowledge-base-20-p7")}
				<br></br>
				<br></br>
				{t("knowledge-base-20-p8")}
			</p>
			<Helmet>
				<meta
					property="og:title"
					content={t("knowledge-base-20-h") + "- Roofko"}
				/>
				<meta property="og:description" content={t("knowledge-base-20-p1")} />
				<title>{t("knowledge-base-20-h") + "- Roofko"}</title>
				<meta name="description" content={t("knowledge-base-20-p1")} />
				<meta property="og:image" content="URL of News Thumbnail Image" />
			</Helmet>
		</>
	);
}

export default Article2;

import React from "react";
import Typography from "@material-ui/core/Typography";

function TermsOfUseElement({ title, body }) {
	return (
		<div>
			<Typography variant="h4" gutterBottom align="left">
				{title}
			</Typography>
			<Typography variant="body1" gutterBottom>
				{body}
			</Typography>
		</div>
	);
}

export default TermsOfUseElement;

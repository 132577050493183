import React from "react";
import "./ProfilePage.css";
import "./TermsOfUse.css";
import PageHero from "../Components/Main/PageHero/PageHero";
import { useTranslation } from "react-i18next";
import TermsOfUseElement from "./TermsOfUseElement";
import Typography from "@material-ui/core/Typography";

import {
	createTheme,
	responsiveFontSizes,
	ThemeProvider,
} from "@material-ui/core/styles";

let theme = createTheme();
theme = responsiveFontSizes(theme);

function PrivacyPolicyPage() {
	const { t } = useTranslation();

	return (
		<div className="profile-wrapper terms-wrapper">
			<>
				<PageHero></PageHero>
				<div className="profile-container">
					<div className="terms-container">
						<ThemeProvider theme={theme}>
							<Typography variant="h2" gutterBottom align="center">
								{t("privacy-policy")}
							</Typography>
							<TermsOfUseElement
								title={t("privacy-policy1")}
								body={t("privacy-policy-body1")}
							></TermsOfUseElement>
							<TermsOfUseElement
								title={t("privacy-policy2")}
								body={t("privacy-policy-body2")}
							></TermsOfUseElement>
							<Typography variant="body1" gutterBottom>
								{t("privacy-policy-body2-2")}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{t("privacy-policy-body2-3")}
							</Typography>
							<TermsOfUseElement
								title={t("privacy-policy3")}
								body={t("privacy-policy-body3")}
							></TermsOfUseElement>
							<Typography variant="body1" gutterBottom>
								{t("privacy-policy-body3-2")}
							</Typography>
							<TermsOfUseElement
								title={t("privacy-policy4")}
								body={t("privacy-policy-body4")}
							></TermsOfUseElement>
							<TermsOfUseElement
								title={t("privacy-policy5")}
								body={t("privacy-policy-body5")}
							></TermsOfUseElement>
							<TermsOfUseElement
								title={t("privacy-policy6")}
								body={t("privacy-policy-body6")}
							></TermsOfUseElement>
							<TermsOfUseElement
								title={t("privacy-policy7")}
								body={t("privacy-policy-body7")}
							></TermsOfUseElement>
							<TermsOfUseElement
								title={t("privacy-policy8")}
								body={t("privacy-policy-body8")}
							></TermsOfUseElement>
							<div>
								<Typography variant="h4" gutterBottom align="left">
									{t("privacy-policy9")}
								</Typography>
								<Typography variant="body1" gutterBottom>
									{t("privacy-policy-body9")}{" "}
									<a href="mailto:info@roofko.me">
										<span style={{ color: "#0000EE" }}>info@roofko.me</span>.
									</a>
								</Typography>
							</div>
						</ThemeProvider>
					</div>
				</div>
			</>
		</div>
	);
}

export default PrivacyPolicyPage;

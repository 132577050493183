import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

export default function BasicTable() {
	const { t } = useTranslation();
	const rows = [
		createData(
			t("table1-h1"),
			t("table1-yes"),
			t("table1-yes"),
			t("table1-no")
		),
		createData(
			t("table1-h2"),
			t("table1-no"),
			t("table1-yes"),
			t("table1-yes")
		),
		createData(
			t("table1-h3"),
			t("table1-grid-cell"),
			t("table1-grid-cell"),
			t("table1-reserve-generator")
		),
		createData(
			t("table1-h4"),
			t("table1-grid-cell"),
			t("table1-grid-cell"),
			t("table1-drop")
		),
		createData(
			t("table1-h5"),
			t("table1-no"),
			t("table1-yes"),
			t("table1-yes")
		),
		createData(
			t("table1-h6"),
			t("table1-low"),
			t("table1-high"),
			t("table1-high")
		),
	];
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 200 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>{t("table1-type")}</TableCell>
						<TableCell align="center">{t("table1-grid")}</TableCell>
						<TableCell align="center">{t("table1-hyprid")}</TableCell>
						<TableCell align="center">{t("table1-autonomous")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow
							key={row.name}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell align="center">{row.calories}</TableCell>
							<TableCell align="center">{row.fat}</TableCell>
							<TableCell align="center">{row.carbs}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

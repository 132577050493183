import React from "react";
import serverUrl from "./serverUrl";
import postData from "./postData";

const requestOffer = async (values, calculationId) => {
	const dataObject = await postData(serverUrl + "/get-offers", {
		email: values.email != "" ? values.email : null,
		phone_number: values.number,
		name: values.firstname,
		surname: values.lastname,
		calculation_id: calculationId,
	})
		.catch((error) => {
			console.error(error.message);
			return error.message;
		})
		.then((data) => {
			return data;
		});
	return dataObject;
};

export default requestOffer;

import React from 'react'
import './PerkItem.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function PerkItem({text,icon,delay}) {
    return (
        <div className='perk-item  slide-in-bottom' style={{animationDelay:delay}}>
            <div className='perk-grid'>
                <FontAwesomeIcon icon={icon} size="lg" />
                <h3>{text}</h3>
            </div>
        </div>
    )
}

export default PerkItem

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Accordion } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./ControlledExpansionPanels.css";
import OutputData from "../OutputData/OutputData";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	// heading: {
	//   fontSize: theme.typography.pxToRem(15),
	//   flexBasis: "33.33%",
	//   flexShrink: 0
	// },
	// secondaryHeading: {
	//   fontSize: theme.typography.pxToRem(15),
	//   color: theme.palette.text.secondary
	// }
}));

function ControlledExpansionPanels({
	rooftop_slope,
	rooftop_azimuth,
	number_of_modules,
	module_generation,
	system_generation,
	annual_consumption,
}) {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(null);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const { t } = useTranslation();

	return (
		<div className="expansion-panels-container">
			<div className={classes.root}>
				<Accordion
					expanded={expanded === "panel1"}
					onChange={handleChange("panel1")}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<h4>{t("expansion_panels_technical_data")}</h4>
					</AccordionSummary>
					<AccordionDetails>
						<OutputData
							value={rooftop_slope}
							unit="  °"
							label={t("expansion_panels_slope")}
						></OutputData>
						<OutputData
							value={rooftop_azimuth}
							unit="  °"
							label={t("expansion_panels_azymuth")}
						></OutputData>
						<OutputData
							value={number_of_modules}
							unit=""
							label={t("expansion_panels_number_of_modules")}
						></OutputData>
						<OutputData
							value={system_generation}
							unit="kWh"
							label={t("expansion_panels_module_generation")}
						></OutputData>
						<OutputData
							value={annual_consumption}
							unit="kWh"
							label={t("annual_consumtion_estimation")}
						></OutputData>
					</AccordionDetails>
				</Accordion>
			</div>
		</div>
	);
}

export default ControlledExpansionPanels;

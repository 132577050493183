import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import "./CountrySelect.css";
import { useTranslation } from "react-i18next";

const SupplierSelect = ({
	selectedCountry,
	selectedSupplier,
	setSelectedSupplier,
	setSupplier,
}) => {
	const { t } = useTranslation();

	const [suppliers, setSuppliers] = useState([]);
	const [activeCode, setActiveCode] = useState([]);

	const supplierCodes = {
		XA: "gen-i",
		XB: "energetika-ljubljana",
		XC: "petrol",
		XD: "e3",
		XE: "elektro-energija",
		XF: "energija-plus",
		XG: "ece",
		XH: "hep-elektra",
		XI: "eon-energija",
		XJ: "energia-naturalis",
		XK: "other",
	};

	// codes for every supplier
	useEffect(() => {
		if (selectedCountry === "HR") {
			setSuppliers(["XH", "XA", "XI", "XJ", "XK"]);
			setSelectedSupplier("XH");
			setSupplier("hep-elektra");
		} else if (selectedCountry === "SI") {
			setSuppliers(["XA", "XB", "XC", "XD", "XE", "XF", "XG", "XK"]);
			setSelectedSupplier("XA");
			setSupplier("gen-i");
		}
	}, [selectedCountry]);

	return (
		<div className="react-flag-select">
			<ReactFlagsSelect
				selected={selectedSupplier}
				onSelect={(code) => {
					setSelectedSupplier(code);
					setSupplier(supplierCodes[code]);
				}}
				countries={suppliers}
				customLabels={{
					XK: t("other"),
				}}
			/>
		</div>
	);
};

export default SupplierSelect;

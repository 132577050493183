import React from 'react'
import './PageHero.css'

function PageHero() {
    return (
        <div className="hero-page">
            <div className='hero-page-shadow'>
                
            </div>
        </div>
    )
}

export default PageHero

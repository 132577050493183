import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	li: {
		transition: "padding-left 0.25s ease-in-out",
		"&:hover": {
			paddingLeft: "5px",
		},
	},
});

function KnowledgeMenuItem({ text, setSelectedId, id, selectedId }) {
	const classes = useStyles();

	return (
		<div
			onClick={() => {
				setSelectedId(id);
			}}
			className="click-element"
		>
			<li className={classes.li}>
				<Typography
					variant="p"
					sx={{
						color: selectedId === id ? "#F37021" : "#000000",
						fontWeight: selectedId === id ? "400" : "300",
					}}
				>
					{text}
				</Typography>
			</li>
		</div>
	);
}

export default KnowledgeMenuItem;

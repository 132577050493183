import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ControlButton from "../Map/ControlButton";
import ExplenationCard from "./ExplenationCard";
import firstExplanationImage from "../../../Assets/Images/explanation1.png";
import secondExplanationImage from "../../../Assets/Images/example_final_2.svg";
import { useTranslation } from "react-i18next";
import "./AlertDialogMeasureExplanation.css";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

function AlertDialogFinishProject({
	alertOpen,
	handleClose,
	handleDontShowAgain,
}) {
	const { t } = useTranslation();

	return (
		<div className="measure-explenation">
			<Dialog
				open={alertOpen}
				className="explenation-dialog-z-index"
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<DialogTitle id="alert-dialog-slide-title">
						{t("alert_dialog_found_your_house")}
						<span className="orangeletters">
							{t("alert_dialog_found_your_house2")}
						</span>
					</DialogTitle>
					<DialogContentText id="alert-dialog-slide-description">
						<h2 className="alert_dialog_measure_explenation_title">
							{t("alert_dialog_measure_explenation_title")}
							<span className="orangeletters">
								{t("alert_dialog_measure_explenation_title2")}
							</span>
							{t("alert_dialog_measure_explenation_title3")}
							<span className="orangeletters">
								{t("alert_dialog_measure_explenation_title4")}
							</span>
						</h2>
						<div className="explanation">
							<ExplenationCard
								image={firstExplanationImage}
								text={t("alert_explenation_text_left")}
							></ExplenationCard>
							{/* <ExplenationCard
								image={secondExplanationImage}
								text={t("alert_explenation_text_right")}
							></ExplenationCard> */}
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{localStorage.getItem("firstTime") === "false" ? (
						""
					) : (
						<ControlButton
							backgroundColor="#a3a3a3"
							color="white"
							text={t("alert_dialog_show_no_more")}
							onClick={handleDontShowAgain}
						></ControlButton>
					)}
					<ControlButton
						backgroundColor="#007bff"
						color="white"
						text={t("alert_dialog_understand")}
						onClick={handleClose}
					></ControlButton>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default AlertDialogFinishProject;

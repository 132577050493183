import React from "react";
import { FileIcon, defaultStyles } from "react-file-icon";

function Subvention({ extension, src, heading, description, type }) {
	return (
		<div className="regulative-grid">
			<div className="regulative-fileicon-container">
				<a href={src} target="_blank">
					<FileIcon
						extension={extension}
						type={type}
						{...defaultStyles[extension]}
					></FileIcon>
				</a>
			</div>
			<div className="regulative-data">
				<div className="regulative-border">
					<h3>
						<a href={src} target="_blank">
							{heading}
						</a>
					</h3>
				</div>
				<p>{description}</p>
			</div>
		</div>
	);
}

export default Subvention;

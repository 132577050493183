import React, {
	useContext,
	createContext,
	useState,
	useRef,
	useEffect,
} from "react";
import ControlButton from "./ControlButton";
import SlopeSlider from "../SlopeSlider/SlopeSlider";
import "./MapWithControls.css";
import createProjectEstimationSimple from "../../../Services/BackComunication/createProjectEstimationSimple";
import ProjectData from "../ProjectData/ProjectData";
import createProject from "../../../Services/BackComunication/createProject";
import { useAuth } from "../../Contexts/auth-context";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PercentageCircleGrade from "../PercentageCircleGrade/PercentageCircleGrade";
import { useTranslation } from "react-i18next";
import CityInput from "./CityInput";
import OrientationSlider from "../OrientationSlider/OrientationSlider";
import CountrySelect from "./CountrySelect";
import SupplierSelect from "./SupplierSelect";
import requestOffer from "../../../Services/BackComunication/requestOffer";

import AskForOfferModal from "../AskForOfferModal/AskForOfferModal";
export const MapWithControlsContext = createContext();

function SimplifiedCalculation({
	selectedCountry,
	setSelectedCountry,
	setSelectedSupplier,
	selectedSupplier,
	setSupplier,
	supplier,
	setCenter,
	modalIsOpen,
	setIsOpen,
}) {
	let history = useHistory();
	const mapRef = useRef(null);

	const { currentUser, userData } = useAuth();

	var ref = useRef(null);
	const [loading, setLoading] = useState(true);
	const [slope, setSlope] = useState(30);
	const [orientation, setOrientation] = useState(180);
	const [estimationNotAllowed, setEstimationNotAllowed] = useState(true);
	const [maxRoofArea, setMaxRoofArea] = useState(100);
	const [projectData, setProjectData] = useState(null);
	const [alertOpen, setAlertOpen] = useState(false);
	const [billValue, setBillValue] = useState(3500);
	const [polygon, setPolygon] = useState(null);
	const [address, setAddress] = useState("");
	const [valute, setValute] = useState("RSD");

	function closeModal() {
		setIsOpen(false);
	}

	const offerRequestSubmit = (data) => {
		requestOffer(data, projectData ? projectData.calculation_id : null);
	};

	var isMobile = false;

	const changeCenter = (object) => {
		setCenter(object);
	};
	const { i18n } = useTranslation();

	useEffect(() => {
		async function init() {
			const data = localStorage.getItem("project");
			setProjectData(JSON.parse(data));
		}
		init();
	}, []);

	const newLocal =
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
	// device detection
	if (
		newLocal.test(navigator.userAgent) ||
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
			navigator.userAgent.substr(0, 4)
		)
	) {
		isMobile = true;
	}

	if (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	) {
		// true for mobile device
		isMobile = true;
	}

	if (window.screen.width <= 480) {
		isMobile = true;
	}

	const estimationResultsRef = useRef(null);

	var scrollTo = document.getElementById("estimation-results");

	const openAlert = () => {
		setAlertOpen(true);
	};

	function handleClose() {
		setAlertOpen(false);
	}
	function handleDontShowAgain() {
		setAlertOpen(false);
		localStorage.setItem("firstTime", false);
	}

	var mapStyleMeasured = {
		borderBottom: 0,
		borderRadius: "0 20px 0px 0",
	};

	const changeRoofTopArea = (area) => {
		setMaxRoofArea(area);
		setEstimationNotAllowed(false);
	};

	const setValuteAndBill = (valute) => {
		setValute(valute);
		if (valute == "€") {
			setBillValue(35);
		} else if (valute == "RSD") {
			setBillValue(3500);
		} else if (valute == "HRK") {
			setBillValue(250);
		}
	};

	const iOS = () => {
		return (
			[
				"iPad Simulator",
				"iPhone Simulator",
				"iPod Simulator",
				"iPad",
				"iPhone",
				"iPod",
			].includes(navigator.platform) ||
			// iPad on iOS 13 detection
			navigator.userAgent.includes("Mac")
		);
	};

	// if you want to restart measure tool. If measuring is finished(measureTool is undefined) then start a new one

	const submitMeasurements = async () => {
		var data = await createProjectEstimationSimple({
			max_rooftop_area: maxRoofArea,
			rooftop_slope: slope,
			electricity_bill: billValue,
			address: address,
			orientation: orientation,
			selectedCountry: selectedCountry,
			supplier: supplier,
		});

		if (data.status == 200) {
			setProjectData(data.body);
			localStorage.setItem("project", JSON.stringify(data.body));
			// scrollTo.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
			estimationResultsRef.current.scrollIntoView();
			setTimeout(() => {
				setLoading(false);
			}, 500);
		}
	};

	const addProject = async () => {
		var projectData = JSON.parse(localStorage.getItem("project"));

		var data = await createProject(projectData, userData.id);

		history.push(`/project/${data.body.project_id}/notlimited`);
		localStorage.removeItem("project");
	};

	const { t } = useTranslation();
	var step2ExplenationTexts = {
		ME: t("step-explenation-2-montenegro"),
		RS: t("step-explenation-2-serbia"),
		HR: t("step-explenation-2-croatia"),
		SI: t("step-explenation-2-slovenia"),
	};
	return (
		<div>
			<MapWithControlsContext.Provider
				value={{
					setPolygon,
				}}
			>
				<div className="map-with-controls-container map-with-controls-container-simplified">
					<div
						className="controls-container"
						style={projectData != null ? mapStyleMeasured : {}}
					>
						<div className="upper-border"></div>
						{/* <div className="step-container-main">
							<div className="step-container">
								<h2>{t("map_with_controls_step6")}</h2>
								<CountrySelect
									selectedCountry={selectedCountry}
									setSelectedCountry={setSelectedCountry}
									setValuteAndBill={setValuteAndBill}
									changeCenter={changeCenter}
								></CountrySelect>
							</div>

							<div className="step-explenation">
								<h3>{t("step-explenation-1")}</h3>
							</div>
						</div> */}

						{selectedCountry === "SI" || selectedCountry === "HR" ? (
							<>
								<div className="upper-border-small"></div>
								<div className="step-container-main">
									<div className="step-container">
										{/* <h2>{t("map_with_controls_step")} 1</h2> */}
										<h2>{t("map_with_controls_step7")}</h2>
										<SupplierSelect
											selectedCountry={selectedCountry}
											setSelectedSupplier={setSelectedSupplier}
											selectedSupplier={selectedSupplier}
											setSupplier={setSupplier}
										></SupplierSelect>
									</div>

									<div className="step-explenation">
										<h3>{t("step-explenation-suplier")}</h3>
									</div>
								</div>
							</>
						) : (
							<></>
						)}

						<div className="upper-border-small"></div>

						<div className="step-container-main">
							<div className="step-container">
								{/* <h2>{t("map_with_controls_step")} 3</h2> */}
								<h2>{t("simplified_calculation_step3")}</h2>
								<div className="output-and-label">
									<label className="otput-label bill-output-label">
										{t("output_area_title")}
									</label>
									<div className="output-fields">
										<input
											className="output-field output-field-bill"
											type="number"
											value={billValue}
											min="0"
											onChange={(e) => {
												setBillValue(e.target.value);
											}}
										/>
										<span className="unit bill-unit">
											&nbsp;{valute} &nbsp;
										</span>
									</div>
								</div>
							</div>

							<div className="step-explenation">
								<h3>{t("step-explenation-3")}</h3>
							</div>
						</div>

						<div className="upper-border-small"></div>

						<div className="step-container-main">
							<div className="step-container">
								{/* <h2>{t("map_with_controls_step")} 4</h2> */}
								{/* delete later */}
								<h2>{t("simplified_calculation_step4")}</h2>

								<CityInput
									address={address}
									setAddress={setAddress}
									setEstimationNotAllowed={setEstimationNotAllowed}
									selectedCountry={selectedCountry}
								></CityInput>
							</div>

							<div className="step-explenation">
								<h3>{t("step-explenation-4")}</h3>
							</div>
						</div>

						<div className="upper-border-small"></div>

						<div className="step-container-main">
							<div className="step-container">
								{/* <div className="step-grid">
									<h2>{t("map_with_controls_step")} 5 </h2>
								</div> */}
								<h2>{t("simplified_calculation_step5")}</h2>
								<div className="output-fields">
									<input
										className="output-field output-field-bill"
										type="number"
										value={maxRoofArea}
										min="0"
										onChange={(e) => {
											changeRoofTopArea(e.target.value);
										}}
									/>
									<span className="unit">
										&nbsp;m
										<sup>
											<sup>2</sup>
										</sup>
										&nbsp;
									</span>
								</div>
							</div>

							<div className="step-explenation">
								<h3>{t("step-explenation-5")}</h3>
							</div>
						</div>

						<div className="upper-border-small"></div>

						<div className="step-container-main">
							<div className="step-container">
								{/* <h2>{t("map_with_controls_step")} 6</h2> */}
								<h2>{t("simplified_calculation_step6")}</h2>
								<OrientationSlider
									orientation={orientation}
									setOrientation={setOrientation}
								></OrientationSlider>
							</div>

							<div className="step-explenation">
								<h3>{t("step-explenation-6")}</h3>
							</div>
						</div>
						<div className="upper-border-small"></div>

						<div className="step-container-main">
							<div className="step-container">
								{/* <h2>{t("map_with_controls_step")} 2</h2> */}
								<h2>{t("map_with_controls_step3")}</h2>
								<SlopeSlider slope={slope} setSlope={setSlope}></SlopeSlider>
							</div>

							<div className="step-explenation">
								<h3>{step2ExplenationTexts[selectedCountry]}</h3>
							</div>
						</div>

						<div className="upper-border-small"></div>
						<div className="step-container" style={{ marginBottom: 0 }}>
							<div className="estimate-profit">
								<ControlButton
									backgroundColor="#ff6b00"
									color="white"
									text={t("map_with_controls_estimate_my_profit")}
									flaticon="flaticon-accounting"
									disabled={estimationNotAllowed}
									onClick={submitMeasurements}
								></ControlButton>
							</div>
						</div>

						<div className="upper-border-small"></div>
					</div>
					<div id="map_with_drawing"></div>
				</div>
			</MapWithControlsContext.Provider>
			{projectData != null ? (
				<div
					className="estimation-results"
					id="estimation-results"
					ref={estimationResultsRef}
				>
					<div className="estimation-results-border">
						<div className="roofko-score white-section">
							<div className="roofko-score-left">
								<h2>
									{t("map_with_controls_score_is")}
									<span className="roofko-score-percent-span">
										{projectData.roofko_score}%
									</span>
								</h2>
								<h3>
									{t("map_with_controls_that_means")}
									{projectData.roofko_score}
									{t("map_with_controls_optimal_location")}
								</h3>
							</div>
							<div className="roofko-score-right">
								<PercentageCircleGrade
									radius={84}
									percent={projectData.roofko_score}
									borderWidth={18}
								/>
							</div>
						</div>
						<div>
							<ProjectData
								projectData={projectData}
								type="homepage"
							></ProjectData>
							<div className="add-project-container" id="add-this-project">
								{currentUser == "investor" ? (
									<ControlButton
										backgroundColor="#ff6b00"
										color="white"
										text={t("map_with_controls_add_this_project")}
										flaticon="flaticon-accounting"
										onClick={addProject}
									></ControlButton>
								) : (
									<>
										<h3 className="please-log-in-h3">
											{t("map_with_controls_please")}

											<a
												onClick={() => {
													setIsOpen(true);
												}}
											>
												{t("map_with_controls_log_in_small")}
											</a>

											{t("map_with_controls_as_investor")}
										</h3>
										<h3 className="please-log-in-h3">
											{t("map_with_controls_will_get")}
										</h3>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			) : (
				""
			)}

			{/* <AlertDialogMeasureExplanation
				alertOpen={alertOpen}
				handleClose={handleClose}
				handleDontShowAgain={handleDontShowAgain}
			></AlertDialogMeasureExplanation> */}
			<AskForOfferModal
				closeModal={closeModal}
				modalIsOpen={modalIsOpen}
				offerRequestSubmit={offerRequestSubmit}
			></AskForOfferModal>
		</div>
	);
}

export default SimplifiedCalculation;

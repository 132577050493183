import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "./BarChart.css";
import { useTranslation } from "react-i18next";

Chart.register(...registerables);

function BarChart({ cashFlow, valute }) {
	var data = cashFlow;

	var backgroundColor = data.map((value) => {
		if (value > 0) {
			return "rgba(245, 105, 0, 0.7)";
		} else {
			return "rgba(31, 107, 94, 0.7)";
		}
	});

	var borderColor = data.map((value) => {
		if (value > 0) {
			return "rgba(245, 105, 0, 1)";
		} else {
			return "rgba(31, 107, 94, 1)";
		}
	});

	const { t } = useTranslation();

	return (
		<div className="bar-chart-container">
			<Bar
				data={{
					labels: [
						"2020",
						"2021",
						"2022",
						"2023",
						"2024",
						"2025",
						"2026",
						"2027",
						"2028",
						"2029",
						"2030",
						"2031",
						"2032",
						"203",
						"2034",
						"2035",
						"2036",
						"2037",
						"2038",
						"2039",
						"2040",
						"2041",
						"2042",
						"2043",
						"2044",
						"2045",
						"2046",
						"2047",
						"2048",
						"2049",
						"2050",
						"2051",
					],
					datasets: [
						{
							label: `${t("bar_chart_money")}${valute}`,
							data: data,
							backgroundColor: backgroundColor,
							borderColor: borderColor,
							borderWidth: 1,
							color: "#999",
						},
					],
				}}
				height={400}
				width={600}
				options={{
					plugins: {
						legend: {
							display: false,
						},
						title: {
							display: false,
							text: `${t("bar_chart_cash_flow")}`,
							size: 20,
						},
					},
					scales: {
						yAxes: {
							title: {
								display: true,
								text: `${t("bar_chart_money")}${valute}`,
								font: {
									size: 15,
								},
							},
						},
						xAxes: {
							title: {
								display: true,
								text: `${t("bar_chart_years")}`,
								font: {
									size: 15,
								},
							},
							ticks: {
								autoSkip: true,
								maxTicksLimit: 8,
							},
						},
					},
				}}
			/>
		</div>
	);
}

export default BarChart;

import serverUrl from "./serverUrl";
import postDataBearer from "./postDataBearer";

const createProject = async (values, id) => {
	const dataObject = await postDataBearer(serverUrl + "/add-project", {
		photo:
			"https://electrek.co/wp-content/uploads/sites/3/2020/02/tesla-solarglass-solar-roof.jpg?quality=82&strip=all&w=1600",
		max_rooftop_area: values.max_rooftop_area,
		rooftop_slope: values.rooftop_slope,
		rooftop_azimuth: values.rooftop_azimuth,
		module_generation: values.module_generation,
		installation_size: values.installation_size,
		life_span: values.life_span,
		repayment_period: values.repayment_period,
		estimated_profit: values.estimated_profit,
		max_estimated_investment: values.max_estimated_investment,
		investment_needed: values.investment_needed,
		cash_flow: values.cash_flow,
		address: values.address,
		module_generation: values.module_generation,
		number_of_modules: values.number_of_modules,
		roofko_score: values.roofko_score,
		investor_id: id,
		system_generation: values.system_generation,
		annual_consumption: values.annual_consumption,
		investment_needed: values.investment_needed,
		rooftop_area_needed: values.rooftop_area_needed,
		country_code: values.country_code,
		supplier: values.supplier,
	})
		.catch((error) => {
			console.error(error.message);
			return error.message;
		})
		.then((data) => {
			return data;
		});

	return dataObject;
};

export default createProject;

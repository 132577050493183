import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LoadingPage from "../Components/LoadingPage/LoadingPage";
import PageHero from "../Components/Main/PageHero/PageHero";
import { useTranslation } from "react-i18next";
import "./PageOfKnowledge.css";
import Article21 from "../Components/Main/PageOfKnowledge/Articles/Article21";

import {
	createTheme,
	responsiveFontSizes,
	ThemeProvider,
} from "@material-ui/core/styles";

function FinancingPage() {
	const articles = [{ title: "Article 21", content: <Article21 />, id: 0 }];

	const articleUrl = [{ id: 0, urlPath: "types-of-solar-systems" }];

	const { i18n } = useTranslation();
	var loading = false;
	const history = useHistory();
	let theme = createTheme();
	theme = responsiveFontSizes(theme);

	return (
		<div className="profile-wrapper knowledgemenu ">
			{loading ? (
				<LoadingPage></LoadingPage>
			) : (
				<>
					<PageHero></PageHero>

					<ThemeProvider theme={theme}>
						<div className="profile-container financing-page">
							<div className="profile-down">
								<div className="white-section project-data-margin-top">
									{articles[0].content}
								</div>
							</div>
						</div>
					</ThemeProvider>
				</>
			)}
		</div>
	);
}

export default FinancingPage;

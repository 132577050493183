import React from "react";

import "./Hero.css";
import "./NotFound.css";
import { textAlign } from "@mui/system";
import { useTranslation } from "react-i18next";

function NotFound() {
	const { t } = useTranslation();

	return (
		<>
			<section className="hero-welcome hero-not-found">
				<div className="right-hero-shadow"></div>
				<div className="bottom-hero-shadow"></div>
				<div className="upper-hero-shadow"></div>

				<div className="hero-container">
					<div className="first-row-container">
						<h1 className="hero-headline" style={{ textAlign: "right" }}>
							404
						</h1>
					</div>
					<h3>Ooops!</h3>
					<h3>{t("not_found_title")}</h3>
					<h3>{t("not_found_subtitle")}</h3>
				</div>
			</section>
		</>
	);
}

export default NotFound;

import React from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import investorIcon from "../../../Assets/Images/investor.svg";
import contractorIcon from "../../../Assets/Images/electrical-engineer.svg";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "./AvatarWithBadge.css";

function AvatarWithBadge({ type, imageUrl }) {
	const SmallAvatar = withStyles((theme) => ({
		root: {
			width: "30%",
			height: "30%",
			border: `2px solid ${theme.palette.background.paper}`,
		},
	}))(Avatar);

	return (
		<Badge
			className="badge-size"
			overlap="circular"
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			badgeContent={
				<SmallAvatar
					alt="Ime i Prezime"
					src={type == "investor" ? investorIcon : contractorIcon}
				/>
			}
			sx={{ width: 300, height: 300 }}
		>
			<Avatar
				alt="Travis Howard"
				src={imageUrl}
				sx={{ width: 100, height: 100 }}
			/>
		</Badge>
	);
}

export default AvatarWithBadge;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { Autocomplete } from "@react-google-maps/api";
import "./SlopeSlider.css";
import roofImage0 from "../../../Assets/Images/roofs/house_roof_0.svg";
import roofImage5 from "../../../Assets/Images/roofs/house_roof_5.svg";
import roofImage10 from "../../../Assets/Images/roofs/house_roof_10.svg";
import roofImage15 from "../../../Assets/Images/roofs/house_roof_15.svg";
import roofImage20 from "../../../Assets/Images/roofs/house_roof_20.svg";
import roofImage25 from "../../../Assets/Images/roofs/house_roof_25.svg";
import roofImage30 from "../../../Assets/Images/roofs/house_roof_30.svg";
import roofImage35 from "../../../Assets/Images/roofs/house_roof_35.svg";
import roofImage40 from "../../../Assets/Images/roofs/house_roof_40.svg";
import roofImage45 from "../../../Assets/Images/roofs/house_roof_45.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "80%",
		margin: "auto",
	},
	margin: {
		height: theme.spacing(3),
	},
}));

const roofImages = {
	0: roofImage0,
	5: roofImage5,
	10: roofImage10,
	15: roofImage15,
	20: roofImage20,
	25: roofImage25,
	30: roofImage30,
	35: roofImage35,
	40: roofImage40,
	45: roofImage45,
};

const marks = [
	{
		value: 0,
		label: "0°",
	},

	{
		value: 15,
		label: "15°",
	},
	{
		value: 30,
		label: "30°",
	},
	{
		value: 45,
		label: "45°",
	},
];

function valuetext(value) {
	return `${value}°C`;
}

export default function SlopeSlider({ slope, setSlope }) {
	const classes = useStyles();

	return (
		<div className="slider-and-image-container">
			<div className="roof-img-with-slope">
				<div style={{ width: "80%", margin: "0 auto" }}>
					<div style={{ width: "auto", display: "inline-block" }}>
						<img
							style={{ width: "100%", margin: "0 auto" }}
							src={roofImages[slope]}
							alt="Roof slope image"
						></img>
					</div>
				</div>
				<p>{slope}°</p>
			</div>
			<div className={classes.root}>
				<Slider
					defaultValue={slope}
					getAriaValueText={valuetext}
					aria-labelledby="discrete-slider-custom"
					step={5}
					valueLabelDisplay="auto"
					marks={marks}
					max={45}
					onChange={(e, value) => {
						setSlope(value);
					}}
					onChangeCommitted={(e, value) => {
						setSlope(value);
					}}
					valueLabelFormat={(x) => {
						return x + "°";
					}}
				/>
			</div>
		</div>
	);
}

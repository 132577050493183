import React from "react";
import serverUrl from "./serverUrl";
import postData from "./postData";

const createProjectEstimationSimple = async (values) => {
	const dataObject = await postData(
		serverUrl + "/simple-size/" + values.selectedCountry.toLowerCase(),
		{
			max_rooftop_area: values.max_rooftop_area,
			rooftop_slope: values.rooftop_slope,
			electricity_bill: parseInt(values.electricity_bill, 10),
			address: values.address,
			rooftop_azimuth: values.orientation,
			supplier: values.supplier,
		}
	)
		.catch((error) => {
			console.error(error.message);
			return error.message;
		})
		.then((data) => {
			return data;
		});
	return dataObject;
};

export default createProjectEstimationSimple;
